import React, { useState } from "react";

import axios from "axios";

import { Link, Navigate } from "react-router-dom";

const Login = () => {
  const image = {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "80%",
  };

  const [state, setState] = React.useState({
    loggedIn: false,
  });

  const [email, setEmail] = useState("");
  const [admin_id, setID] = useState("");
  const [password, setPassword] = useState("");

  const [emailErr, setEmailErr] = useState({});
  const [idErr, setidErr] = useState({});
  const [passwordErr, setPasswordErr] = useState({});

  const onSubmit = (b) => {
    b.preventDefault();
    const isValid = formValidation();
    //console.log("works");

    localStorage.setItem("email", "");
    localStorage.setItem("fullname", "");

    if (isValid) {
      const users = {
        id: admin_id,
        email: email,
        password: password,
      };

      //console.log(users);

      axios({
        method: "post",
        url: "/v1/",
        data: users,
        config: { headers: { "Content-Type": "multiport/form-data" } },
      })
        .then(function (response) {
          //console.log(response);
          if (response.data.message === "success") {
            localStorage.setItem("token_id", response.data.token);
            localStorage.setItem("username", response.data.user);
            setState({
              loggedIn: true,
            });
          } else {
            setState({
              loggedIn: false,
              message: "Incorrect details used!. Try again!",
              alerts: "text-center fs-4 mt-3 mb-3 alert alert-danger",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const formValidation = () => {
    const emailErr = {};
    const passwordErr = {};
    const idErr = {};
    let isValid = true;

    if (password.trim().length < 5) {
      passwordErr.passwordWrong = "Password is too short";
      isValid = false;
    }

    if (!password) {
      passwordErr.passwordWrong = "Password is required here";
      isValid = false;
    }

    if (!email) {
      emailErr.emailWrong = "Email is required here";
      isValid = false;
    }

    if (!admin_id) {
      idErr.idWrong = "Email is required here";
      isValid = false;
    }

    setEmailErr(emailErr);
    setPasswordErr(passwordErr);
    setidErr(idErr);

    return isValid;
  };

  if (state.loggedIn) {
    return <Navigate to={"/auth"} />;
  } else {
    return (
      <div className="bg-white-gradient-bottom">
        <div class="container d-flex flex-column">
          <div class="row align-items-center justify-content-center g-0 min-vh-100">
            <div class="col-lg-5 col-md-8 py-3 py-xl-0">
              <div class="card">
                <div class="card-body p-6">
                  <div class="mb-4">
                    <Link to="/" className="ms-8 text-center">
                      <img
                        src="../assets/images/brand/logo/smfreelance-2.png"
                        className="mb-4 text-center"
                        width="260"
                        alt=""
                        style={image}
                      />
                    </Link>
                    <h2 class="text-center text-success mb-1 fw-bold">Admin Sign in</h2>
                  </div>

                  <div class={state.alerts} role="alert">
                    {state.message}
                  </div>

                  <form onSubmit={onSubmit}>
                    <div className="mb-3">
                      <label for="name" className="form-label">
                       Admin ID
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Admin ID"
                        onChange={(e) => {
                          setID(e.target.value);
                          idErr.idWrong = "";
                        }}
                      />
                      {Object.keys(idErr).map((key) => {
                        return (
                          <div className="text-danger fs-5 mt-3">
                            {idErr[key]}
                          </div>
                        );
                      })}
                    </div>

                    <div className="mb-3">
                      <label for="name" className="form-label">
                        Email Address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email Address"
                        onChange={(e) => {
                          setEmail(e.target.value);
                          emailErr.emailWrong = "";
                        }}
                      />
                      {Object.keys(emailErr).map((key) => {
                        return (
                          <div className="text-danger fs-5 mt-3">
                            {emailErr[key]}
                          </div>
                        );
                      })}
                    </div>

                    <div className="mb-3">
                      <label for="password" className="form-label">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        onChange={(e) => {
                          setPassword(e.target.value);
                          passwordErr.passwordWrong = "";
                        }}
                      />
                      {Object.keys(passwordErr).map((key) => {
                        return (
                          <div className="text-danger fs-5 mt-3">
                            {passwordErr[key]}
                          </div>
                        );
                      })}
                    </div>

                    <div className="mt-4">
                      <div class="d-grid">
                        <button type="submit" class="btn btn-dark ">
                          Log In To Account
                        </button>
                      </div>
                    </div>
                    <hr class="my-4" />
                    <div class="mt-4 text-center">
                      <a
                        href="https://t.me/+aO5rVEkmkJ5mNzBk"
                        target="_blank"
                        class="btn-social btn-social-outline btn-facebook"
                      >
                        <i class="fab fa-telegram"></i>
                      </a>

                      <a
                        href="https://twitter.com/SmEduFreelance?s=09"
                        target="_blank"
                        class="btn-social btn-social-outline btn-twitter"
                      >
                        <i class="fab fa-twitter"></i>
                      </a>

                      <a
                        href="https://www.instagram.com/smartedufreelance?r=nametag"
                        target="_blank"
                        class="btn-social btn-social-outline btn-instagram"
                      >
                        <i class="fab fa-instagram"></i>
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Login;
