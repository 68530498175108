import React from "react";

const Logout = () => {
  setTimeout(function () {
    localStorage.clear();
    window.location.href = "../";
  }, 5000);

  return (
    <>
      <div className="col-md-12 text-center p-20 py-15 mt-5">
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>

        <h4 className="mt-10 h3 fw-bold text-dark">Logging Off Device!</h4>
      </div>
    </>
  );
};

export default Logout;
