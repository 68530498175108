import React from "react";

import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

const FilesApp = () => {
  const getusers = useSelector((state) => state.user.value);

  const getname = getusers.fullname;

  const getemail = getusers.email;

  const getdate = getusers.days;

  const total_user = getusers.total_user;

  const total_income = getusers.total_income;

  const total_revenue = getusers.total_revenue;

  const total_order = getusers.total_order;

  const total_payouts = getusers.total_payouts;


  let fname_1;

  if (getname) {
    fname_1 = getname.split(" ")[1];
  } else {
    fname_1 = "";
  }

  
  return (
    <div class="container-fluid p-4 py-12">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12 mb-2">
          <div
            class="d-lg-flex
          align-items-center justify-content-between"
          >
            <div class="mb-2 mb-lg-0">
              <h1 class="mb-0 h2 fw-bold">File Lists</h1>
            </div>
            <div class="d-flex align-items-center">
              <Link
                to="/promo"
                class="btn btn-icon fs-3 btn-light border border-2 rounded-circle btn-dashed ms-2"
              >
                +
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 mb-4">
          <ul class="nav nav-lb-tab">
            <li class="nav-item ms-0 me-3">
              <Link to="/home" class="nav-link">
                Dashboard
              </Link>
            </li>
            <li class="nav-item mx-3">
              <Link to="/task" class="nav-link">
                Task
              </Link>
            </li>
            <li class="nav-item mx-3">
              <Link to="/users" class="nav-link">
                Users
              </Link>
            </li>
            <li class="nav-item mx-3">
              <Link to="/promos" class="nav-link">
                All Promo
              </Link>
            </li>
            <li class="nav-item mx-3">
              <Link to="/files" class="nav-link active">
                Files
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        
      </div>
    </div>
  );
};

export default FilesApp;
