import React, { useState } from "react";

import axios from "axios";

import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

toast.configure();

const SecurityApp = () => {
  const getusers = useSelector((state) => state.user.value);

  const getname = getusers.fullname;

  const getemail = getusers.email;

  let fname_1;

  if (getname) {
    fname_1 = getname.split(" ")[1];
  } else {
    fname_1 = "";
  }

  const pathname = window.location.hash;

  const Email_config = "ON";

  const SetEmail = (clicked) => {
    axios({
      method: "post",
      url: "/v1/settings.php",
      data: {
        token: clicked,
        status: "EMAIL",
        email: getemail,
        fullname: getname,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success("Success: Notifications to Email has been activated");
        } else {
          toast.error("Error: Request Failed! Try again");
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check your connection..");
      });
  };

  const [schools, setOrders] = useState(null);
  const [message, setMessage] = useState("");

  const handleChange = async (event) => {
    const searchinput = event.target.value;

    const response = await axios.post("./v1/activity.php", {
      email: getemail,
      q: searchinput,
    });

    const view = response.data;

    if (view.message === "failed") {
      toast.error("Error: Request Failed! Check your connection..");
      setMessage(view.message);
    } else {
      setOrders(view);
    }
  };

  const fetchOrder = async () => {
    const response = await axios.post("./v1/activity.php", {
      email: getemail,
      q: null,
    });

    const view = response.data;

    if (view.message === "failed") {
      toast.error("Error: Request Failed! Check your connection..");
      setMessage(view.message);
    } else {
      setOrders(view);
    }
  };

  const renderTable = () => {
    if (message === "failed") {
      return <></>;
    } else if (schools) {
      return schools.map((school, index) => {
        //const TimeDate = new Date(school.tm_date).toDateString();
        //console.log(school.tm_date)
        return (
          <>
            <tr >
              <td class="align-middle">
                <div class="fw-semi-bold">
                  <div class="icon-shape icon-md bg-light-primary text-primary rounded-circle me-2">
                    {school.title === "Admin Login System" ? (
                      <>
                        <i class="fe fe-shield"></i>
                      </>
                    ) : school.title === "Payouts System" ? (
                      <>
                        <i class="fe fe-credit-card"></i>
                      </>
                    ) : school.title === "Promo System" ? (
                      <>
                        <i class="fe fe-message-square"></i>
                      </>
                    ) : school.title === "Chat System" ? (
                      <>
                        <i class="fe fe-mail"></i>
                      </>
                    ) : (
                      <>
                        <i class="fe fe-check"></i>
                      </>
                    )}
                  </div>
                  <>{school.title}</>
                </div>
              </td>

              <td class="align-middle">{school.message}</td>

              <td class="align-middle">{school.device_type}</td>

              <td class="align-middle">{school.date_time}</td>

            </tr>
          </>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="5">
            <div className="col-md-12 text-center py-5 mt-5">
              <div class="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>

              <h4 className="mt-6 h5 text-dark">Touch Screen With Mouse</h4>
            </div>
          </td>
        </tr>
      );
    }
  };

  return (
    <div class="container-fluid p-4 py-12">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12 mb-2">
          <div
            class="d-lg-flex
          align-items-center justify-content-between"
          >
            <div class="mb-2 mb-lg-0">
              <h1 class="mb-0 h2 fw-bold">Settings</h1>
            </div>
            <div class="d-flex align-items-center">
              <Link
                to="/promo"
                class="btn btn-icon fs-3 btn-light border border-2 rounded-circle btn-dashed ms-2"
              >
                +
              </Link>
            </div>
          </div>
        </div>
      </div>

      {pathname === "#/notifications" ? (
        <>
          <div class="row">
            <div class="col-12 mb-4">
              <ul class="nav nav-lb-tab">
                <li class="nav-item ms-0 me-3">
                  <Link to="/settings" class="nav-link">
                    App Settings{" "}
                  </Link>
                </li>
                <li class="nav-item mx-3">
                  <Link to="/notifications" onClick={fetchOrder} class="nav-link active">
                    Notifications{" "}
                    <span class="badge bg-success text-white">
                      All Activities
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </>
      ) : (
        <>
          <div class="row">
            <div class="col-12 mb-4">
              <ul class="nav nav-lb-tab">
                <li class="nav-item ms-0 me-3">
                  <Link to="/settings" class="nav-link active">
                    App Settings{" "}
                  </Link>
                </li>
                <li class="nav-item mx-3">
                  <Link to="/notifications" onClick={fetchOrder} class="nav-link">
                    Notifications{" "}
                    <span class="badge bg-success text-white">
                      All Activities
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </>
      )}

      {pathname === "#/notifications" ? (
        <>
          <div class="row">
            <div class="col-md-12 mb-5">
              <form class="d-flex align-items-center">
                <span class="position-absolute ps-3 search-icon">
                  <i class="fe fe-search"></i>
                </span>
                <input
                  type="search"
                  class="form-control form-control-md ps-6"
                  placeholder="Search orders table lists"
                  onChange={handleChange}
                />
              </form>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <h4 class="mb-0 text-dark">All Activities</h4>
                </div>

                <div class="table-responsive overflow-y-hidden">
                  <table class="table mb-10 text-nowrap table-hover table-borderless">
                    <thead class="table-white">
                      <tr>
                        <th scope="col" class="border-top-0">
                          Syetem Type
                        </th>
                        <th scope="col" class="border-top-0">
                          Details
                        </th>
                        <th scope="col" class="border-top-0 ">
                          Device
                        </th>
                        <th scope="col" class="border-top-0 ">
                          Time & Date
                        </th>
                      </tr>
                    </thead>
                    <tbody>{renderTable()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div class="row">
            <div class="col-lg-12 py-3">
              <div class="card">
                <div class="card-header">
                  <h4 class="mb-0 text-primary">Security Settings</h4>
                </div>

                <div class="card-body">
                  <p>
                    Security settings as made avaliable options to configure the
                    Admin App account. Adjust settings to desired
                    configurations. Also note that the graphical analysis below
                    shows the admin dashboard activity usage.
                  </p>

                  <div className="row py-4">
                    <div className="col-6">
                      <h5>Send All Notifications To Email</h5>
                    </div>
                    <div className="col-6">
                      <div class="form-check form-switch  mb-2">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckChecked"
                          value={Email_config}
                          onChange={(b) => {
                            SetEmail(b.target.value);
                          }}
                        />
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckChecked"
                        >
                          Check to switch {Email_config}
                        </label>
                      </div>
                    </div>

                    <div className="col-6 mt-5">
                      <h5>Enable 2FA Two Factor Authentication To Email</h5>
                    </div>
                    <div className="col-6 mt-5">
                      <div class="form-check form-switch  mb-2">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckChecked"
                          value={Email_config}
                          onChange={(b) => {
                            SetEmail(b.target.value);
                          }}
                        />
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckChecked"
                        >
                          Check to switch {Email_config}
                        </label>
                      </div>
                    </div>

                    <div className="col-6 mt-5">
                      <h5>Lock To Only This Device</h5>
                    </div>
                    <div className="col-6 mt-5">
                      <div class="form-check form-switch  mb-2">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckChecked"
                          value={Email_config}
                          onChange={(b) => {
                            SetEmail(b.target.value);
                          }}
                        />
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckChecked"
                        >
                          Check to switch {Email_config}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SecurityApp;
