import React, { useState } from "react";

import axios from "axios";

const PromoList = (getemail) => {
  const email = getemail.getemail;

  const [state, setState] = React.useState({
    loggedIn: false,
  });

  const End = (clicked) => {
    const end_data = {
      token: clicked,
      status: "END",
    };

    axios({
      method: "post",
      url: "/v1/promo_stats.php",
      data: end_data,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          fetchData();
        } else {
          
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Delete = (clicked) => {
    const end_data = {
      token: clicked,
      status: "DELETED",
    };

    axios({
      method: "post",
      url: "/v1/promo_stats.php",
      data: end_data,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          fetchData();
        } else {
          
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [schools, setSchools] = useState(null);

  const [message, setMessage] = useState("");

  const fetchData = async () => {
    const response = await axios.post("./v1/PromoList.php", { email: email });

    const view = response.data;

    if (view.message === "failed") {
      setMessage(view.message);
    } else {
      setSchools(view);
    }
  };

  const renderTable = () => {
    if (schools == "") {
      return (
        <>
          <div className="col-md-12 py-5 mt-1 text-center">
            <img
              src="../assets/images/background/empty.png"
              alt="Gallery image 3"
              class="gallery__img rounded-3"
              style={{ width: "50%" }}
            />

            <h4 className="fs-3 fw-bold mt-4">No Records Found</h4>
          </div>
        </>
      );
    } else if (schools) {
      return schools.map((school, index) => {
        const TimeDate = new Date(school.tm_date).toDateString();
        //console.log(school.tm_date)
        return (
          <>
            {school.status_code != "LIVE" ? (
              <>
                <div
                  className="col-md-12 py-5 mt-1 text-center"
                  onMouseMove={fetchData}
                  onTouchMove={fetchData}
                >
                  <img
                    src="../assets/images/background/waiting.svg"
                    alt="Gallery image 3"
                    class="gallery__img rounded-3"
                    style={{ width: "60%" }}
                    onMouseMove={fetchData}
                    onTouchMove={fetchData}
                  />

                  <h4 className="fs-4 fw-semi-bold mt-6">
                    Waiting for you to upload live promo..
                  </h4>
                </div>
              </>
            ) : (
              <>
                <div class="card">
                  <div class="card-header">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <h4 class="mb-0">
                          <i class="text-success fs-3 bi bi-dot"></i>Ongoing
                          Promo Sale
                        </h4>
                      </div>

                      <div class="">
                        <span class="dropdown dropstart">
                          <a
                            class="text-muted text-decoration-none"
                            href="#"
                            role="button"
                            id="DropdownTen"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="fe fe-more-vertical"></i>
                          </a>
                          <span
                            class="dropdown-menu"
                            aria-labelledby="DropdownTen"
                          >
                            <span class="dropdown-header">Settings</span>
                            <a class="btn dropdown-item"
                            id={school.token}
                            onClick={(e) => {
                              Delete(e.target.id);
                            }}>Delete</a>
                            <a
                              class="btn dropdown-item"
                              id={school.token}
                              onClick={(e) => {
                                End(e.target.id);
                              }}
                            >
                              End Promo
                            </a>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    {school.message.replace("?", "₦")}

                    <ul class="list-group list-group-flush">
                      <li class="list-group-item px-0">
                        <div
                          class="d-flex justify-content-between
                      align-items-center"
                        >
                          <div class="d-flex align-items-center">
                            <i class="bi bi-calendar fs-4 text-primary"></i>
                            <div class="ms-2">
                              <h5 class="mb-0 text-body">Start Date</h5>
                            </div>
                          </div>
                          <div class="">
                            <div>
                              <p
                                class="text-dark mb-0
                              fw-semi-bold"
                              >
                                {school.begin}
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div
                          class="d-flex justify-content-between
                      align-items-center"
                        >
                          <div class="d-flex align-items-center">
                            <i class="bi bi-calendar-event fs-4 text-primary"></i>
                            <div class="ms-2">
                              <h5 class="mb-0 text-body">End Date</h5>
                            </div>
                          </div>
                          <div class="">
                            <div>
                              <p
                                class="text-dark mb-0
                              fw-semi-bold"
                              >
                                {school.end}
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item  px-0">
                        <div
                          class="d-flex justify-content-between
                  align-items-center"
                        >
                          <div class="d-flex align-items-center">
                            <i class="bi bi-card-heading fs-4 text-primary"></i>
                            <div class="ms-2">
                              <h5 class="mb-0 text-body">Promo Title</h5>
                            </div>
                          </div>
                          <div class="">
                            <div>
                              <p
                                class="text-dark mb-0
                            fw-semi-bold"
                              >
                                {school.title.replace("?", "₦")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            )}
          </>
        );
      });
    } else {
      return (
        <div
          className="col-md-12 text-center py-5 mt-5"
          onMouseMove={fetchData}
          onTouchMove={fetchData}
        >
          <div class="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>

          <h4 className="mt-6 h5 text-dark">Touch Screen With Mouse</h4>
        </div>
      );
    }
  };

  return (
    <div class="col-12 mb-4" onMouseMove={fetchData} onTouchMove={fetchData}>
      {renderTable()}
    </div>
  );
};

export default PromoList;
