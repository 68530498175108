import React from "react";

import Chart from "react-apexcharts";

import { useSelector } from "react-redux";

const Charts = () => {

  const getusers = useSelector((state) => state.user.value);

  const total_user = getusers.total_user;

  const total_income = getusers.total_income;

  const total_revenue = getusers.total_revenue;

  const total_order = getusers.total_order;

  const total_payouts = getusers.total_payouts;

  const config = {
    series: [total_user, total_income, total_revenue, total_payouts, total_order],
    options: {
     labels: ['Total Users', 'Total Income Revenue', 'Total Made Revenue', 'Total Payments', 'Total Proposals'],
      chart: {
        type: "donut",
        toolbar: {
            show: false,
          },
      },
      legend: {
        show: false
      },
      theme: {
        palette: "palette2",
      },
      dataLabels: {
        enabled: false,
      },
      cutout: "80%",
      cutoutPercentage: 90,
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        animateScale: true,
        animateRotate: true,
      },
      tooltips: {
        enabled: true,
        intersect: false,
        mode: "nearest",
        bodySpacing: 5,
        yPadding: 10,
        xPadding: 10,
        caretPadding: 0,
        displayColors: false,
        cornerRadius: 4,
        footerSpacing: 0,
        titleSpacing: 0,
      },
      plugins: {
        legend: {
          display: false,
          show: true
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            labels: ['Total Users', 'Total Income Revenue', 'Total Made Revenue', 'Total Payments', 'Total Proposals'],
            chart: {
              width: 270,
              height: 200,
            },
            legend: {
              show: false
            },
          },
        },
      ],
    },
  };

  return (
    <div class="card mb-4 ">
      <div class="card-body">
        <h4 class=" card-title ">Overall Progress</h4>

        <Chart
          options={config.options}
          series={config.series}
          type="donut"
          height={260}
        />
      </div>
    </div>
  );
};

export default Charts;
