import React, { useState } from "react";

import axios from "axios";

import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { useDispatch } from "react-redux";

import { register } from "../features/Users";

import { Link } from "react-router-dom";

import Search from "./Search";

import Sidebar from "./Sidebar";

import HomeApp from "./Screens/Home";

import { Alert } from "./Screens/Alert";

toast.configure();

const Home = () => {

  const dispatch0 = useDispatch();

  const get_user_data0 = window.localStorage.getItem("user_data");

  // console.log(get_user_data0);

  let verify_user;

  if (get_user_data0) {
    verify_user = dispatch0(register(JSON.parse(get_user_data0)));
  }else{
    window.location.href = "../";
  }

  console.log(verify_user)

  const getname = window.localStorage.getItem("username");

  let fname_1;

  if (getname) {

    fname_1 = getname.split(" ")[1];
  } else {
    fname_1 = "";
  }

  const [search, setSearch] = React.useState(null);

  function handleChange(event) {
    const searchinput = event.target.value;

    if (searchinput) {
      setSearch(searchinput);
    } else {
      setSearch(null);
    }

    //console.log(searchinput);
  }

  // const getemail = 'paulezsoftwarecorp@gmail.com';

  // const fetchOrder = async () => {
  //   const response = await axios.post("./v1/notifications.php", {
  //     email: getemail
  //   });

  //   const view = response.data;

  //   if (view.message === "failed") {
  //     toast.error("Error: Request Failed! Check your connection..");
     
  //   } else {
  //     toast.info("News: "+ view.fullname +' - '+ view.message, {
  //       theme: 'dark',
  //       icon: false,
  //       position:"bottom-right",
  //       hideProgressBar: true,
  //       draggable: true,
  //       autoClose: 20000 });
      
  //   }
  // };

  return (
    <div id="db-wrapper">
      <Sidebar />

      <div id="page-content">
        <div class="header fixed-top">
          <nav class="navbar-default navbar navbar-expand-lg">
            <a
              id="nav-toggle"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fe fe-menu"></i>
            </a>

            <div
              class="dropdown-menu dropdown-menu-end dropdown-menu-lg bg-light-gradient-top"
              aria-labelledby="nav-toggle"
            >
              <div class="nav-scroller">
                <div class=" ">
                  <div class="border-bottom px-3 pb-3 d-flex justify-content-between align-items-center">
                    <span class="h4 fw-bold text-primary mb-0">Menu</span>
                  </div>
                  <ul class="list-group list-group-flush notification-list-scroll">
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-md-12 py-2">
                          <Link to="/home" class="fs-4 fw-semi-bold text-dark">
                            <i class="nav-icon fe fe-home me-2"></i> Dashboard
                          </Link>
                        </div>

                        <div class="col-md-12 py-2">
                          <Link to="/users" class="fs-4 fw-semi-bold text-dark">
                            <i class="nav-icon fe fe-users me-2"></i> All Users
                          </Link>
                        </div>

                        <div class="col-md-12 py-2">
                          <Link
                            to="/schools"
                            class="fs-4 fw-semi-bold text-dark"
                          >
                            <i class="nav-icon bi bi-bank2 me-2"></i> All
                            Schools
                          </Link>
                        </div>

                        <div class="col-md-12 py-2">
                          <Link
                            to="/orders"
                            class="fs-4 fw-semi-bold text-dark"
                          >
                            <i class="nav-icon bi bi-cart-check me-2"></i>{" "}
                            Orders
                          </Link>
                        </div>

                        <div class="col-md-12 py-2">
                          <Link
                            to="/payouts"
                            class="fs-4 fw-semi-bold text-dark"
                          >
                            <i class="nav-icon bi bi-wallet-fill me-2"></i>{" "}
                            Payouts
                          </Link>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <div class="border-bottom border-top px-3 pt-3 pb-3 d-flex justify-content-between align-items-center">
                    <span class="h4 fw-bold text-primary mb-0">Apps</span>
                  </div>
                  <ul class="list-group list-group-flush notification-list-scroll">
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-md-12 py-2">
                          <a
                            href="https://app.jivosite.com/chat/inbox"
                            target="_blank"
                            class="fs-4 fw-semi-bold text-hover-primary text-dark"
                          >
                            <i class="nav-icon fe fe-home me-2"></i> Chat
                          </a>
                        </div>

                        <div class="col-md-12 py-2">
                          <Link to="/pay" class="fs-4 fw-semi-bold text-dark">
                            <i class="nav-icon mdi mdi-credit-card me-2"></i>{" "}
                            Payment
                          </Link>
                        </div>

                        <div class="col-md-12 py-2">
                          <Link
                            to="/tracker"
                            class="fs-4 fw-semi-bold text-dark"
                          >
                            <i class="nav-icon bi bi-app-indicator me-2"></i>
                            Tracker{" "}
                            <span class="badge bg-success ms-2">Ver 1.0</span>
                          </Link>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <div class="border-bottom border-top px-3 pt-3 pb-3 d-flex justify-content-between align-items-center">
                    <span class="h4 fw-bold text-primary mb-0">Account</span>
                  </div>
                  <ul class="list-group list-group-flush notification-list-scroll">
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-md-12 py-2">
                          <Link
                            to="/profile"
                            class="fs-4 fw-semi-bold text-dark"
                          >
                            <i class="nav-icon fe fe-clipboard me-2"></i>{" "}
                            Profile
                          </Link>
                        </div>

                        <div class="col-md-12 py-2">
                          <Link
                            to="/settings"
                            class="fs-4 fw-semi-bold text-dark"
                          >
                            <i class="nav-icon fe fe-git-pull-request me-2"></i>{" "}
                            Settings
                            <span class="badge bg-primary ms-2">2FA</span>
                          </Link>
                        </div>

                        <div class="col-md-12 py-2">
                          <a
                            href="./#/help-center"
                            target="_blank"
                            class="fs-4 fw-semi-bold text-hover-primary text-dark"
                          >
                           <i class="nav-icon bi bi-box-arrow-in-up-right me-2"></i> Help Center
                          </a>
                        </div>

                      </div>
                    </li>
                  </ul>

                </div>
              </div>
            </div>

            <div class="ms-lg-3 d-none d-md-none d-lg-block col-4">
              <div class="d-flex align-items-center">
                <span class="position-absolute ps-3 search-icon">
                  <i class="fe fe-search"></i>
                </span>
                <input
                  type="search"
                  class="form-control form-control-sm ps-6"
                  placeholder="Search Entire Dashboard"
                  onChange={handleChange}
                />
              </div>
            </div>

            <ul class="navbar-nav navbar-right-wrap ms-auto d-flex nav-top-wrap">
             
             <Alert/>

              <li class="dropdown ms-2">
                <a
                  class="rounded-circle"
                  role="button"
                  id="dropdownUser"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div class="avatar avatar-md avatar-indicators avatar-online">
                    <img
                      alt="avatar"
                      src="../../assets/images/avatar/bot3.svg"
                      class="rounded-circle"
                    />
                  </div>
                </a>
                <div
                  class="dropdown-menu dropdown-menu-end"
                  aria-labelledby="dropdownUser"
                >
                  <div class="dropdown-item">
                    <div class="d-flex">
                      <div class="avatar avatar-md avatar-indicators avatar-online">
                        <img
                          alt="avatar"
                          src="../../assets/images/avatar/bot3.svg"
                          class="rounded-circle"
                        />
                      </div>
                      <div class="ms-3 lh-1">
                        <h5 class="mb-1">{getname}</h5>
                        <p class="mb-0 text-muted">{fname_1}@smartedung.com</p>
                      </div>
                    </div>
                  </div>
                  <div class="dropdown-divider"></div>
                  <ul class="list-unstyled">
                    <li>
                      <Link to="/profile" class="dropdown-item">
                        <i class="fe fe-user me-2"></i> Profile
                      </Link>
                    </li>
                    <li>
                      <Link to="/settings" class="dropdown-item">
                        <i class="fe fe-settings me-2"></i> Settings
                      </Link>
                    </li>
                  </ul>
                  <div class="dropdown-divider"></div>
                  <ul class="list-unstyled">
                    <li>
                      <Link to="/logout" class="dropdown-item">
                        <i class="fe fe-power me-2"></i> Sign Out
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </nav>
        </div>
        {search ? <Search search = {search} /> : <HomeApp />}
      </div>
      
    </div>
  );
};

export default Home;
