import React, { useEffect } from "react";

import { useDispatch } from "react-redux";

import { register } from "../features/Users";

import axios from "axios";

import { Navigate } from "react-router-dom";

const Auth = () => {
  const dispatch = useDispatch();

  const [state, setState] = React.useState({
    user: false,
  });

  const jwttokens = {
    jwt: localStorage.getItem("token_id"),
  };

  useEffect(() => {
    const post = async () => {
      axios({
        method: "post",
        url: "/v1/protected-users.php",
        data: jwttokens,
        config: { headers: { "Content-Type": "multiport/form-data" } },
      })
        .then(function (response) {
          // console.log(response.user_data.data.fullname);
          //console.log(response)
          if (response.data.message === "success") {
            localStorage.setItem(
              "user_data",
              JSON.stringify(response.data.user_data.data)
            );
            setState({
              user: true,
            });
          } else {
            setState({
              user: false,
            });
          }
        })
        .catch((response) => {
          console.log(response);
        });
    };
    post();
  });

  // dispatch(register(userdata));

  const get_user_data = window.localStorage.getItem("user_data");

  console.log(get_user_data);

  dispatch(register(JSON.parse(get_user_data)));

  if (get_user_data) {

    setTimeout(function () {
      window.location.href = "/#/home";
    }, 5000);

    return (
      <div>
        <div className="col-md-12 text-center py-10 mt-20">
          <div class="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    );
  } else {
    localStorage.setItem("token", "");
    localStorage.setItem("tokenid", "");
    localStorage.setItem("user_data", "");
    localStorage.setItem("token_id", "");
    return <Navigate to={"../"}/>;
  }
};

export default Auth;
