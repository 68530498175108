import ReactDOM from "react-dom";

import { HashRouter, Route, Routes } from "react-router-dom";

import Login from "./Login";

import NoPage from "./NoPage";

import AppHome from "./App/Home";

import Promo from "./App/Screens/Promo";

import { configureStore } from '@reduxjs/toolkit';

import { Provider } from 'react-redux';

import useReducer from "./features/Users";

import './index.css';

import Auth from "./App/Auth";

import Task from "./App/Task";

import Users from "./App/Users";

import Promos from "./App/Promos";

import Files from "./App/Files";

import Schools from "./App/Schools";

import Orders from "./App/Orders";

import Payouts from "./App/Payouts";

import Tracker from "./App/Tracker";

import Profile from "./App/Profile";

import Security from "./App/Security";

import AlertApp from "./App/AlertApp";

import Logout from "./App/Logout";


const store = configureStore({
  reducer: {
    user: useReducer,
  }
})

export default function App() {
  return (
    <HashRouter>
      <Routes>
          <Route index element={<Login />} />
          <Route path="home" element={<AppHome />} />
          <Route path="auth" element={<Auth />} />
          <Route path="promo" element={<Promo />} />
          <Route path="task" element={<Task />} />
          <Route path="users" element={<Users />} />
          <Route path="promos" element={<Promos />} />
          <Route path="files" element={<Files />} />
          <Route path="schools" element={<Schools />} />
          <Route path="schools_1" element={<Schools />} />
          <Route path="orders" element={<Orders />} />
          <Route path="orders_1" element={<Orders />} />
          <Route path="payouts" element={<Payouts />} />
          <Route path="payouts_1" element={<Payouts />} />
          <Route path="tracker" element={<Tracker />} />
          <Route path="profile" element={<Profile />} />
          <Route path="settings" element={<Security />} />
          <Route path="notifications" element={<Security />} />
          <Route path="alerts" element={<AlertApp />} />
          <Route path="logout" element={<Logout />} />
          <Route path="*" element={<NoPage />} />
      </Routes>
    </HashRouter>
  );
}

ReactDOM.render( <Provider store={store}><App /></Provider>, document.getElementById("root"));