import React, { useState, useEffect } from "react";

import axios from "axios";

import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import Carousel from "react-bootstrap/Carousel";

toast.configure();

const Search = (search) => {
  const getsearch = search.search;

  const getusers = useSelector((state) => state.user.value);

  const getname = getusers.fullname;

  const getemail = getusers.email;

  //console.log(getsearch.toLowerCase());

  const [notify, setNotify] = useState(null);
  const [users, setUsers] = useState(null);
  const [message, setMessage] = useState("");

  useEffect(() => {
    axios({
      method: "post",
      url: "/v1/activity.php",
      data: {
        email: getemail,
        q: getsearch,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message === "failed") {
          toast.error("Error: Request Failed! Check your connection..");
          setMessage(view.message);
        } else {
          setNotify(view);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check your connection..");
      });
  }, []);

  useEffect(() => {
    axios({
      method: "post",
      url: "/v1/users.php",
      data: {
        email: getemail,
        q: getsearch,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message === "failed") {
          toast.error("Error: Request Failed! Check your connection..");
          setMessage(view.message);
        } else {
          setUsers(view);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check your connection..");
      });
  }, []);

  const renderTable = () => {
    if (message === "failed") {
      return <></>;
    } else if (notify) {
      return notify.map((school, index) => {
        //const TimeDate = new Date(school.tm_date).toDateString();
        //console.log(school.tm_date)
        return (
          <>
            <li class="list-group-item px-0 pt-0 border-0 pb-6">
              <div class="row position-relative">
                <div class="col-auto">
                  <div class="icon-shape icon-md bg-light-primary text-primary rounded-circle">
                    {school.title === "Admin Login System" ? (
                      <>
                        <i class="fe fe-shield"></i>
                      </>
                    ) : school.title === "Payouts System" ? (
                      <>
                        <i class="fe fe-credit-card"></i>
                      </>
                    ) : school.title === "Promo System" ? (
                      <>
                        <i class="fe fe-message-square"></i>
                      </>
                    ) : school.title === "Chat System" ? (
                      <>
                        <i class="fe fe-mail"></i>
                      </>
                    ) : (
                      <>
                        <i class="fe fe-check"></i>
                      </>
                    )}
                  </div>
                </div>
                <div class="col ms-n3">
                  <h4 class="mb-0 h5">{school.title}</h4>
                  <p class="mb-0 fs-6 text-body">{school.message}</p>
                </div>
                <div class="col-auto">
                  <span class="text-muted fs-6">{school.date_},</span>
                </div>
              </div>
            </li>
          </>
        );
      });
    } else {
      return <></>;
    }
  };

  const renderUsers = () => {
    if (message === "failed") {
      return <></>;
    } else if (users) {
      return users.map((school, index) => {
        //const TimeDate = new Date(school.tm_date).toDateString();
        //console.log(school.tm_date)
        return (
          <Carousel.Item>
            <div class="py-5 mb-4">
              <div class="card-body">
                <div class="d-lg-flex">
                  <div class="position-relative">
                    <span class="avatar avatar-lg avatar-success">
                      <span class="avatar-initials rounded-circle fs-4">
                        {school.fullname.split(" ")[0].charAt(0).toUpperCase() +
                          school.fullname.split(" ")[1].charAt(0).toUpperCase()}
                      </span>
                    </span>
                  </div>
                  <div class="ms-lg-4">
                    <h4 class="mb-0">{school.fullname}</h4>
                    <p class="mb-0 fs-6">{school.job}</p>
                    {school.schools <= "2" ? (
                      <p class="fs-6 mb-1 text-warning">
                        <span>1.0</span>
                        <span class="mdi mdi-star text-warning me-2"></span>
                        Beginner Level
                      </p>
                    ) : school.schools <= "10" ? (
                      <p class="fs-6 mb-1 text-danger">
                        <span>2.0</span>
                        <span class="mdi mdi-star text-danger me-2"></span>
                        Expert Member
                      </p>
                    ) : school.schools <= "20" ? (
                      <p class="fs-6 mb-1 text-info">
                        <span>3.0</span>
                        <span class="mdi mdi-star text-info me-2"></span>
                        Gold Member
                      </p>
                    ) : school.schools <= "30" ? (
                      <p class="fs-6 mb-1 text-primary">
                        <span>4.0</span>
                        <span class="mdi mdi-star text-primary me-2"></span>
                        Diamond Member
                      </p>
                    ) : school.schools >= "31" ? (
                      <p class="fs-6 mb-1 text-dark">
                        <span>5.0</span>
                        <span class="mdi mdi-star text-dark me-2"></span>
                        Platinium Member
                      </p>
                    ) : (
                      <></>
                    )}
                    <p class="fs-6 text-muted">
                      <span class="ms-2">
                        <span class="text-dark fw-medium">
                          {school.schools}
                        </span>{" "}
                        Schools
                      </span>
                      <span class="ms-2">
                        <span class="text-dark fw-medium">₦{school.total}</span>{" "}
                        Total Income
                      </span>
                    </p>
                    <p></p>
                    <a
                      class="btn btn-outline-white btn-sm"
                      role="button"
                      id="dropdownNotification"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      View Details
                    </a>
                    <div
                      class="dropdown-menu dropdown-menu-end dropdown-menu-md"
                      aria-labelledby="dropdownNotification"
                    >
                      <div class=" ">
                        <div class="border-bottom px-3 pb-3 d-flex justify-content-between align-items-center">
                          <span class="h4 mb-0">{school.fullname}</span>
                        </div>

                        <ul class="list-group list-group-flush notification-list-scroll">
                          <li class="list-group-item bg-white">
                            <div class="row">
                              <div class="col">
                                <a class="text-body">
                                  <div class="">
                                    <div className="col mb-2">
                                      <span className="me-2">Phone:-</span>{" "}
                                      {school.phone}
                                    </div>

                                    <div className="col mb-2">
                                      <span className="me-2">Email:-</span>{" "}
                                      {school.email}
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        );
      });
    } else {
      return <></>;
    }
  };

  if (getsearch.toLowerCase() == "users" || getsearch.toLowerCase() == "user") {
    return (
      <div class="container-fluid p-10">
        <>
          <div>
            <h3 class="mt-5 mb-0">Menu Content Search</h3>
          </div>
          <Link to='/users'>
            <div className="col-md-4 py-4">
              <div class="card mb-4 bg-primary border-primary card-hover">
                <div class="card-body">
                  <h4 class="card-title text-white"></h4>

                  <div class="d-flex justify-content-between align-items-center mt-8">
                    <div>
                      <h1 class="display-4 text-white mb-1"> Users</h1>
                      <p class="mb-0 text-white"></p>
                    </div>
                    <div>
                      <i class="bi bi-people fs-1 text-light-primary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </>
      </div>
    );
  }

  if (getsearch.toLowerCase() == "settings" || getsearch.toLowerCase() == "setting") {
    return (
      <div class="container-fluid p-10">
        <>
          <div>
            <h3 class="mt-5 mb-0">Menu Content Search</h3>
          </div>
          <Link to='/settings'>
            <div className="col-md-4 py-4">
              <div class="card mb-4 bg-danger border-danger card-hover">
                <div class="card-body">
                  <h4 class="card-title text-white"></h4>

                  <div class="d-flex justify-content-between align-items-center mt-8">
                    <div>
                      <h1 class="display-4 text-white mb-1"> Settings</h1>
                      <p class="mb-0 text-white"></p>
                    </div>
                    <div>
                      <i class="bi bi-gear fs-1 text-light-danger"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </>
      </div>
    );
  }

  if (getsearch.toLowerCase() == "pay" || getsearch.toLowerCase() == "payout" || getsearch.toLowerCase() == "payouts") {
    return (
      <div class="container-fluid p-10">
        <>
          <div>
            <h3 class="mt-5 mb-0">Menu Content Search</h3>
          </div>
          <Link to='/payouts'>
            <div className="col-md-4 py-4">
              <div class="card mb-4 bg-success border-success card-hover">
                <div class="card-body">
                  <h4 class="card-title text-white"></h4>

                  <div class="d-flex justify-content-between align-items-center mt-8">
                    <div>
                      <h1 class="display-4 text-white mb-1"> Payout</h1>
                      <p class="mb-0 text-white"></p>
                    </div>
                    <div>
                      <i class="bi bi-credit-card fs-1 text-light-success"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </>
      </div>
    );
  }

  if (getsearch.toLowerCase() == "order" || getsearch.toLowerCase() == "orders") {
    return (
      <div class="container-fluid p-10">
        <>
          <div>
            <h3 class="mt-5 mb-0">Menu Content Search</h3>
          </div>
          <Link to='/orders'>
            <div className="col-md-4 py-4">
              <div class="card mb-4 bg-info border-info card-hover">
                <div class="card-body">
                  <h4 class="card-title text-white"></h4>

                  <div class="d-flex justify-content-between align-items-center mt-8">
                    <div>
                      <h1 class="display-4 text-white mb-1"> Orders</h1>
                      <p class="mb-0 text-white"></p>
                    </div>
                    <div>
                      <i class="bi bi-cart-plus fs-1 text-light-info"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </>
      </div>
    );
  }

  if (notify || users) {
    return (
      <div class="container-fluid p-10">
        <>
          <div>
            <h3 class="mt-3 mb-0">Search Results</h3>
          </div>
          <div class="row">
            <div className="col-md-6">
              <div class="mt-4">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h4 class="mb-0">Recent Activity</h4>
                  </div>
                </div>

                <div class="card-body">
                  <ul class="list-group list-group-flush list-timeline-activity">
                    {renderTable()}
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-12">
              <div>
                <h4 class="mb-0">Users Profile</h4>
              </div>
              <Carousel variant="dark" controls={false}>
                {renderUsers()}
              </Carousel>
            </div>
          </div>
        </>
      </div>
    );
  } else {
    return (
      <div class="container-fluid p-10">
        <>
          <div className="text-center pt-4 pt-md-20">
            <h3 class="mt-5 mb-0">No Result Found</h3>
          </div>
        </>
      </div>
    );
  }
};

export default Search;
