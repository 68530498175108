import React, { useState } from "react";

import axios from "axios";

import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link } from "react-router-dom";

import { useSelector } from "react-redux";


toast.configure();

const ProfileApp = () => {
  const getusers = useSelector((state) => state.user.value);

  const getname = getusers.fullname;

  const getemail = getusers.email;

  let fname_1;

  if (getname) {
    fname_1 = getname.split(" ")[1];
  } else {
    fname_1 = "";
  }

  const [state, setState] = React.useState({
    isSubmit: "",
    buttonname: "Change Password",
  });

  const [pass1, setPass1] = React.useState(null);
  const [pass2, setPass2] = React.useState(null);


  const onSubmit = (b) => {
    b.preventDefault();

    const user_contact = {
      email: getemail,
      password1: pass1,
      password2: pass2
    };

    axios({
      method: "post",
      url: "/v1/security.php",
      data: user_contact,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response.data.data.account_name);
        if (response.data.message === 'success') {
          toast.success("Success: Password was successfully updated!");
        } else if(response.data.message === 'invalid'){
          toast.warning("Match: Password don't match! Try again");
        }
        else {
          toast.error("Error: Request Failed! Try again");
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check your connection..");
      });
  };


  return (
    <div class="container-fluid p-4 py-12">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12 mb-2">
          <div
            class="d-lg-flex
          align-items-center justify-content-between"
          >
            <div class="mb-2 mb-lg-0">
              <h1 class="mb-0 h2 fw-bold">
                <i class="bi bi-dot text-success"></i> Profile Update{" "}
              </h1>
            </div>
            <div class="d-flex align-items-center">
              <Link
                to="/promo"
                class="btn btn-icon fs-3 btn-light border border-2 rounded-circle btn-dashed ms-2"
              >
                +
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div class="">
        <div class="col-lg-6 offset-lg-3 py-3">
          <div class="card">
            <div class="card-header">
              <h4 class="mb-0 text-primary">Password Update</h4>
            </div>

            <div class="card-body">
              <form class="mt-3" onSubmit={onSubmit}>
                <div className="">
                  <div class="col-md-12 mb-5">
                    <h4>New Password</h4>
                    <input
                      type="password"
                      class="form-control form-control-md px-4"
                      placeholder="Search user/agent"
                      required
                      onChange={(e) => {
                        setPass1(e.target.value);
                        setState({
                          buttonname: "Change Password",
                        });
                      }}
                    />
                  </div>

                  <div class="col-md-12 mb-5">
                    <h4>Confirm Password</h4>
                    <input
                      type="password"
                      class="form-control form-control-md px-4"
                      placeholder="Search user/agent"
                      required
                      onChange={(e) => {
                        setPass2(e.target.value);
                        setState({
                          buttonname: "Change Password",
                        });
                      }}
                    />
                  </div>

                  <div class="col-md-12 mb-5">
                    <button type="submit" class="btn btn-dark">
                      <span className="fs-5">{state.buttonname}</span>{" "}
                      <span className={state.isSubmit}></span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileApp;
