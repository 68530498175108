import React, { useState } from "react";

import axios from "axios";

import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import CustomScroll from "react-custom-scroll";

toast.configure();

const PromosApp = () => {
  const getusers = useSelector((state) => state.user.value);

  const getname = getusers.fullname;

  const getemail = getusers.email;


  let fname_1;

  if (getname) {
    fname_1 = getname.split(" ")[1];
  } else {
    fname_1 = "";
  }

  const [schools, setSchools] = useState(null);

  const handleChange = async (event) => {

    const searchinput = event.target.value;

    const response = await axios.post("./v1/PromoLists.php", { email: getemail, q: searchinput });
    
    const view = response.data;

    if (view.message === "failed") {
      toast.error("Error: Request Failed! Check your connection..");
    } else {
      setSchools(view);
    }

  }

  const truncate = (str, max, suffix) =>
    str.length < max
      ? str
      : `${str.substr(
          0,
          str.substr(0, max - suffix.length).lastIndexOf(" ")
        )}${suffix}`;

  const fetchData = async () => {

    const response = await axios.post("./v1/PromoLists.php", { email: getemail, q: null });
    
    const view = response.data;

    if (view.message === "failed") {
      toast.error("Error: Request Failed! Check your connection..");
    } else {
      setSchools(view);
    }

  };

  const renderTable = () => {
    if (schools == "") {
      return (
        <>
          <tr>
            <td colspan="7">
              <div className="col-md-12 py-5 mt-1 text-center">
                <img
                  src="../assets/images/background/empty.png"
                  alt="Gallery image 3"
                  class="gallery__img rounded-3"
                  style={{ width: "20%" }}
                />

                <h4 className="fs-3 fw-bold mt-4">
                  No Result Found...
                </h4>
              </div>
            </td>
          </tr>
        </>
      );
    } else if (schools) {
      return schools.map((school, index) => {
        const TimeDate = new Date(school.tm_date).toDateString();
        //console.log(school.tm_date)
        return (
          <>
            {school.status_code == "Completed" ? (
              <></>
            ) : (
              <>
                <tr>
                <td class="align-middle">{index + 1}</td>
                  <td class="align-middle">{school.title.replace("?", "₦")}</td>
                  <td class="align-middle">{truncate(school.message.replace("?", "₦"), 30, "...")}</td>
                  <td class="align-middle">{school.begin}</td>
                  <td class="align-middle">{school.end}</td>
                  <td class="align-middle">{school.trn_date}</td>
                  <td class="align-middle">
                  {school.status_code == "LIVE" ? (
                      <span
                        class="badge bg-success fs-6"
                        
                      >
                        LIVE
                      </span>
                    ) : (
                      <span
                        class="badge bg-danger fs-6"
                        
                      >
                        END
                      </span>
                       )}
                  </td>
                </tr>
              </>
            )}
          </>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="7">
            <div
              className="col-md-12 text-center py-5 mt-5"
              onMouseMove={fetchData}
              onTouchMove={fetchData}
            >

              <div class="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>

              <h4 className="mt-6 h5 text-dark">Touch Screen With Mouse</h4>
            </div>
          </td>
        </tr>
      );
    }
  };

  
  return (
    <div class="container-fluid p-4 py-12">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12 mb-2">
          <div
            class="d-lg-flex
          align-items-center justify-content-between"
          >
            <div class="mb-2 mb-lg-0">
              <h1 class="mb-0 h2 fw-bold">Promo Lists</h1>
            </div>
            <div class="d-flex align-items-center">
              <Link
                to="/promo"
                class="btn btn-icon fs-3 btn-light border border-2 rounded-circle btn-dashed ms-2"
              >
                +
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 mb-4">
          <ul class="nav nav-lb-tab">
            <li class="nav-item ms-0 me-3">
              <Link to="/home" class="nav-link">
                Dashboard
              </Link>
            </li>
            <li class="nav-item mx-3">
              <Link to="/task" class="nav-link">
                Task
              </Link>
            </li>
            <li class="nav-item mx-3">
              <Link to="/users" class="nav-link">
                Users
              </Link>
            </li>
            <li class="nav-item mx-3">
              <Link to="/promos" class="nav-link active">
                All Promo
              </Link>
            </li>
            <li class="nav-item mx-3">
              <Link to="/files" class="nav-link">
                Files
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-4 mb-5">
          <form class="d-flex align-items-center">
            <span class="position-absolute ps-3 search-icon">
              <i class="fe fe-search"></i>
            </span>
            <input
              type="search"
              class="form-control form-control-md ps-6"
              placeholder="Search promo table"
              onChange={handleChange}
            />
          </form>
        </div>

        <div class="col-12" >
          <div class="card">
            <div class="card-header">
              <h4 class="mb-0 text-dark">Promo Table</h4>
            </div>

            <CustomScroll>
              <div class="table-responsive overflow-y-hidden">
                <table class="table mb-10 text-nowrap table-hover table-borderless">
                  <thead class="table-white">
                    <tr>
                      <th scope="col" class="border-top-0">
                        S/N
                      </th>
                      <th scope="col" class="border-top-0">
                        Title
                      </th>
                      <th scope="col" class="border-top-0 ">
                        Message
                      </th>
                      <th scope="col" class="border-top-0 ">
                        Begin
                      </th>
                      <th scope="col" class="border-top-0 ">
                        End
                      </th>
                      
                    </tr>
                  </thead>
                  <tbody>{renderTable()}</tbody>
                </table>
              </div>
            </CustomScroll>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromosApp;
