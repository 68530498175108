import React, { useState } from "react";

import axios from "axios";

import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

toast.configure();

export const Alert = () => {
  const getusers = useSelector((state) => state.user.value);

  const getemail = getusers.email;

  const [schools, setOrders] = useState(null);
  const [message, setMessage] = useState("");

  const fetchOrder = async () => {
    const response = await axios.post("./v1/client_notify.php", {
      email: getemail
    });

    const view = response.data;

    if (view.message === "failed") {
      toast.error("Error: Request Failed! Check your connection..");
      setMessage(view.message);
    } else {
      setOrders(view);
    }
  };

  const renderTable = () => {
    if (message === "failed") {
      return <></>;
    } else if (schools) {
      return schools.map((school, index) => {
        //const TimeDate = new Date(school.tm_date).toDateString();
        //console.log(school.tm_date)
        return (
          <>
            <li class="list-group-item bg-light">
              <div class="row">
                <div class="col">
                  <a class="text-body">
                    <div class="d-flex">
                    <span class="avatar avatar-md avatar-danger">
                        <span class="avatar-initials rounded-circle px-3">
                          {school.fullname
                            .split(" ")[0]
                            .charAt(0)
                            .toUpperCase() +
                            school.fullname
                              .split(" ")[1]
                              .charAt(0)
                              .toUpperCase()}
                        </span>
                      </span>
                      <div class="ms-3">
                        <h5 class="fw-bold mb-1">{school.fullname}</h5>
                        <p class="mb-3">
                        {school.fullname}:- {school.message}
                        </p>
                        <span class="fs-6 text-muted">
                          <span>
                            <span class="fe fe-thumbs-up text-success me-1"></span>
                            {school.date_}
                          </span>
                          <span class="ms-1">{school.time_}</span>
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
                
              </div>
            </li>
          </>
        );
      });
    } else {
      return (
        <li>
            <div className="col-md-12 text-center py-5 mt-5"
            onMouseMove={fetchOrder}
            onTouchMove={fetchOrder} >
              <div class="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>

              <h4 className="mt-6 h5 text-dark">Touch Screen With Mouse</h4>
            </div>
        </li>
      );
    }
  };

  return (
    <li class="dropdown stopevent">
      <a
        class="btn btn-light btn-icon rounded-circle indicator indicator-primary text-muted"
        role="button"
        id="dropdownNotification"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={fetchOrder}
      >
        <i class="fe fe-bell"></i>
      </a>
      <div
        class="dropdown-menu dropdown-menu-end dropdown-menu-lg"
        aria-labelledby="dropdownNotification"
      >
        <div class=" ">
          <div class="border-bottom px-3 pb-3 d-flex justify-content-between align-items-center">
            <span class="h4 mb-0">Notifications</span>
            <a href="# " class="text-muted">
              <span class="align-middle">
                <i class="fe fe-settings me-1"></i>
              </span>
            </a>
          </div>

          <ul class="list-group list-group-flush notification-list-scroll">
          {renderTable()}
          </ul>
          <div class="border-top px-3 pt-3 pb-0">
            <Link to="/alerts" class="text-link fw-semi-bold">
              See all Notifications
            </Link>
          </div>
        </div>
      </div>
    </li>
  );
};
