import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {
  fullname: "",
  days: "",
  email: "",
  total_user: "",
  total_income: "",
  total_revenue: "",
  total_order: "",
  total_payouts: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState: { value: initialStateValue },
  reducers: {
    register: (state, action) => {
      state.value = action.payload;
    },

    logout: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { register, logout } = userSlice.actions;

export default userSlice.reducer;
