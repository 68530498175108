import React, { useState } from "react";

import axios from "axios";

import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import CustomScroll from "react-custom-scroll";

import Charts from "./Chart";

import Notify from "./Notify";

import PromoList from "./PromoList";

toast.configure();

const HomeApp = () => {
  const getusers = useSelector((state) => state.user.value);

  const getname = getusers.fullname;

  const getemail = getusers.email;

  const getdate = getusers.days;

  const total_user = getusers.total_user;

  const total_income = getusers.total_income;

  const total_revenue = getusers.total_revenue;

  const total_order = getusers.total_order;

  const total_payouts = getusers.total_payouts;

  let fname_1;

  if (getname) {
    fname_1 = getname.split(" ")[1];
  } else {
    fname_1 = "";
  }

  const d = new Date();
  const n = d.toDateString();

  const start_date = new Date(getdate);

  const difference = Math.abs(d - start_date);
  const days = Math.round(difference / (1000 * 3600 * 24));

  const [schools, setSchools] = useState(null);

  const fetchData = async () => {
    const response = await axios.post("./v1/user.php", {
      email: getemail,
    });

    const view = response.data;

    if (view.message === "failed") {
      toast.error("Error: Request Failed! Check your connection..");
    } else {
      setSchools(view);
    }
  };

  const renderTable = () => {
    if (schools == "") {
      return (
        <>
          <tr>
            <td colspan="7">
              <div className="col-md-12 py-5 mt-1 text-center">
                <img
                  src="../assets/images/background/empty.png"
                  alt="Gallery image 3"
                  class="gallery__img rounded-3"
                  style={{ width: "20%" }}
                />

                <h4 className="fs-3 fw-bold mt-4">No Result Found...</h4>
              </div>
            </td>
          </tr>
        </>
      );
    } else if (schools) {
      return schools.map((school, index) => {
        const TimeDate = new Date(school.tm_date).toDateString();
        //console.log(school.tm_date)
        return (
          <>
            {school.status_code == "Completed" ? (
              <></>
            ) : (
              <>
                <tr>
                  <td class="align-middle">
                    <div class="d-flex align-items-center">
                      <span class="avatar avatar-md avatar-danger">
                        <span class="avatar-initials rounded-circle">
                          {school.fullname
                            .split(" ")[0]
                            .charAt(0)
                            .toUpperCase() +
                            school.fullname
                              .split(" ")[1]
                              .charAt(0)
                              .toUpperCase()}
                        </span>
                      </span>
                      <div class="ms-3">
                        <h5 class="mb-0">
                          <a class="text-inherit">{school.fullname} </a>
                        </h5>
                      </div>
                    </div>
                  </td>
                  <td class="align-middle">
                    <a href={`mailto:${school.email}`}>{school.email}</a>
                    , <br /> <a href={`Tel:${school.phone}`}>{school.phone}</a>
                  </td>
                  <td class="align-middle">{school.trn_date}</td>
                  
                </tr>
              </>
            )}
          </>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="7">
            <div
              className="col-md-12 text-center py-5 mt-5"
              onMouseMove={fetchData}
              onTouchMove={fetchData}
            >
              <div class="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>

              <h4 className="mt-6 h5 text-dark">Touch Screen With Mouse</h4>
            </div>
          </td>
        </tr>
      );
    }
  };

  return (
    <div class="container-fluid p-4 py-12">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12 mb-2">
          <div
            class="d-lg-flex
          align-items-center justify-content-between"
          >
            <div class="mb-2 mb-lg-0">
              <h1 class="mb-0 h2 fw-bold">Hello {fname_1}, Welcome Back!</h1>
            </div>
            <div class="d-flex align-items-center">
              <Link
                to="/promo"
                class="btn btn-icon fs-3 btn-light border border-2 rounded-circle btn-dashed ms-2"
              >
                +
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 mb-4">
          <ul class="nav nav-lb-tab">
            <li class="nav-item ms-0 me-3">
              <Link to="/home" class="nav-link active">
                Dashboard
              </Link>
            </li>
            <li class="nav-item mx-3">
              <Link to="/task" class="nav-link">
                Task
              </Link>
            </li>
            <li class="nav-item mx-3">
              <Link to="/users" class="nav-link">
                Users
              </Link>
            </li>
            <li class="nav-item mx-3">
              <Link to="/promos" class="nav-link">
                All Promo
              </Link>
            </li>
            <li class="nav-item mx-3">
              <Link to="/files" class="nav-link">
                Files
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-xl-8 col-12">
          <div class="row">
            <PromoList getemail={getemail} />

            <div class="col-md-12 mb-4">
              <div class="card">
                <div class="card-header">
                  <h4 class="mb-0">Statistics </h4>
                </div>

                <div class="row">
                  <div class="col-lg-4 col-md-12 col-12">
                    <div class="d-flex align-items-center justify-content-between p-4">
                      <div class="">
                        <h2 class="h3 fw-bold mb-0">₦{total_income}</h2>
                        <p class="mb-0">Total Income Revenue</p>
                      </div>
                      <div class="ms-3">
                        <div class="icon-shape icon-lg bg-light-primary text-primary rounded-circle">
                          <i class="fe fe-pie-chart fs-3"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-12 col-12 border-start-md">
                    <div class="d-flex align-items-center justify-content-between p-4">
                      <div class="">
                        <h2 class="h3 fw-bold mb-0">₦{total_revenue}</h2>
                        <p class="mb-0">Total Made Revenue Per-Term</p>
                      </div>
                      <div class="ms-3">
                        <div class="icon-shape icon-lg bg-light-danger text-danger rounded-circle">
                          <i class="fe fe-dollar-sign fs-3"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-12 col-12 border-start-md">
                    <div class="d-flex align-items-center justify-content-between p-4">
                      <div class="">
                        <h2 class="h3 fw-bold mb-0">₦{total_payouts}</h2>
                        <p class="mb-0">Total Payments</p>
                      </div>
                      <div class="ms-3">
                        <div class="icon-shape icon-lg bg-light-success text-success rounded-circle">
                          <i class="bi bi-wallet-fill fs-3"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 mb-4">
              <div class="card">
                <div class="card-header">
                  <h4 class="mb-0">New Users</h4>
                </div>

                <div class="table-responsive overflow-y-hidden">
                  <table class="table mb-0 text-nowrap">
                    <thead class="table-white">
                      <tr>
                        <th scope="col" class="border-top-0">
                          member
                        </th>
                        <th scope="col" class="border-top-0 ">
                          Contacts
                        </th>
                        <th scope="col" class="border-top-0 ">
                          Time and Date{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>{renderTable()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-xl-4 col-12">
          <div class="card mb-4 bg-primary border-primary">
            <div class="card-body">
              <h4 class="card-title text-white">Launch Date </h4>

              <div class="d-flex justify-content-between align-items-center mt-8">
                <div>
                  <h1 class="display-4 text-white mb-1">{days} Days</h1>
                  <p class="mb-0 text-white">{n}</p>
                </div>
                <div>
                  <i class="fe fe-flag fs-1 text-light-primary"></i>
                </div>
              </div>
            </div>
          </div>

          <Charts />

          <Notify />
        </div>
      </div>
    </div>
  );
};

export default HomeApp;
