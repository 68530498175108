import React, { useState } from "react";

import axios from "axios";

import { useSelector } from "react-redux";

const PromoTable = () => {
  const getusers = useSelector((state) => state.user.value);

  const getemail = getusers.email;

  const [promo, setPromo] = useState(null);

  const [state, setState] = React.useState({
    loggedIn: false,
  });

  const End = (clicked) => {
    const end_data = {
      token: clicked,
      status: "END",
    };

    axios({
      method: "post",
      url: "/v1/promo_stats.php",
      data: end_data,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          setState({
            loggedIn: true,
            message: "Promo has been terminated!",
            alerts: "text-center fs-4 mt-3 mb-3 alert alert-success",
          });
        } else {
          setState({
            loggedIn: false,
            message: "Oops! Something went wrong. Please try again",
            alerts: "text-center fs-4 mt-3 mb-3 alert alert-danger",
          });
        }
      })
      .catch((err) => {
        setState({
          loggedIn: false,
          message: "Oops! Something went wrong. Please try again",
          alerts: "text-center fs-4 mt-3 mb-3 alert alert-danger",
        });
      });
  };

  const Delete = (clicked) => {
    const end_data = {
      token: clicked,
      status: "DELETED",
    };

    axios({
      method: "post",
      url: "/v1/promo_stats.php",
      data: end_data,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          setState({
            loggedIn: true,
            message: "Promo has been deleted!",
            alerts: "text-center text-dark fs-4 mt-3 mb-3 alert alert-warning",
          });
        } else {
          setState({
            loggedIn: false,
            message: "Oops! Something went wrong. Please try again",
            alerts: "text-center fs-4 mt-3 mb-3 alert alert-danger",
          });
        }
      })
      .catch((err) => {
        setState({
          loggedIn: false,
          message: "Oops! Something went wrong. Please try again",
          alerts: "text-center fs-4 mt-3 mb-3 alert alert-danger",
        });
      });
  };

  const Live = (clicked) => {
    const end_data = {
      token: clicked,
      status: "LIVE",
    };

    axios({
      method: "post",
      url: "/v1/promo_stats.php",
      data: end_data,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          setState({
            loggedIn: true,
            message: "Promo has been made LIVE!",
            alerts: "text-center text-dark fs-4 mt-3 mb-3 alert alert-success",
          });
        } else {
          setState({
            loggedIn: false,
            message: "Oops! Something went wrong. Please try again",
            alerts: "text-center fs-4 mt-3 mb-3 alert alert-danger",
          });
        }
      })
      .catch((err) => {
        setState({
          loggedIn: false,
          message: "Oops! Something went wrong. Please try again",
          alerts: "text-center fs-4 mt-3 mb-3 alert alert-danger",
        });
      });
  };

  const Preview = async (clicked) => {
    //console.log(clicked);

    setState({
      loggedIn: false,
      message: "",
      alerts: "",
    });

    const response = await axios.post("./v1/PromoListed.php", {
      token: clicked,
    });

    const view = response.data;

    setPromo(view);
  };

  const [schools, setSchools] = useState(null);

  const [message, setMessage] = useState("");

  const fetchData = async () => {
    const response = await axios.post("./v1/PromoLists.php", {
      email: getemail,
      q: null,
    });

    const view = response.data;

    if (view.message === "failed") {
      setMessage(view.message);
    } else {
      setSchools(view);
    }
  };

  const truncate = (str, max, suffix) =>
    str.length < max
      ? str
      : `${str.substr(
          0,
          str.substr(0, max - suffix.length).lastIndexOf(" ")
        )}${suffix}`;

  const renderTable = () => {
    if (schools == "") {
      return (
        <>
          <div className="col-md-12 text-center py-15 mt-5">
            <div class="lds-ripple">
              <div></div>
              <div></div>
            </div>
            <h4 className="mt-4 h4 text-white">No Records Found</h4>
          </div>
        </>
      );
    } else if (schools) {
      return schools.map((school, index) => {
        const TimeDate = new Date(school.tm_date).toDateString();
        //console.log(school.tm_date)
        return (
          <>
            {school.status_code != "DELETED" ? (
              <li class="nav-item border-top border-dark">
                <div className="btn">
                  <h4 class="mb-0 text-light h5">
                    {index + 1}. {school.title.replace("?", "₦")}
                  </h4>
                  <p class="mt-2 fs-6 text-gray-500">
                    {truncate(school.message.replace("?", "₦"), 50, "...")}
                    {school.status_code == "LIVE" ? (
                      <span
                        class="badge bg-success fs-6"
                        data-bs-toggle="modal"
                        data-bs-target=".gd-example-modal-lg"
                        id={school.token}
                        onClick={(e) => {
                          Preview(e.target.id);
                        }}
                      >
                        LIVE
                      </span>
                    ) : (
                      <span
                        class="badge bg-danger fs-6"
                        data-bs-toggle="modal"
                        data-bs-target=".gd-example-modal-lg"
                        id={school.token}
                        onClick={(e) => {
                          Preview(e.target.id);
                        }}
                      >
                        END
                      </span>
                    )}
                  </p>
                </div>
              </li>
            ) : (
              <></>
            )}
          </>
        );
      });
    } else {
      return (
        <div className="col-md-12 text-center py-15 mt-5">
          <div class="lds-ripple">
            <div></div>
            <div></div>
          </div>
          <h4 className="mt-4 h5 text-white">Touch Screen With Mouse</h4>
        </div>
      );
    }
  };

  const renderPreview = () => {
    if (promo == "") {
      return (
        <>
          <div className="col-md-12 py-5 mt-1 text-center">
            <img
              src="../assets/images/background/network.png"
              alt="Gallery image 3"
              class="gallery__img rounded-3"
              style={{ width: "30%" }}
            />

            <h4 className="fs-3 fw-semi-bold mt-5">Please re-select list...</h4>
          </div>
        </>
      );
    } else if (promo) {
      return promo.map((school, index) => {
        const TimeDate = new Date(school.tm_date).toDateString();
        //console.log(school.tm_date)
        return (
          <>
            <div class="card-body">
              <p className="mb-4 text-dark">
                {school.message.replace("?", "₦")}
              </p>
              <ul class="list-group list-group-flush">
                <li class="list-group-item px-0">
                  <div
                    class="d-flex justify-content-between
                      align-items-center"
                  >
                    <div class="d-flex align-items-center">
                      <i class="bi bi-calendar fs-4 text-primary"></i>
                      <div class="ms-2">
                        <h5 class="mb-0 text-body">Start Date</h5>
                      </div>
                    </div>
                    <div class="">
                      <div>
                        <p
                          class="text-dark mb-0
                              fw-semi-bold"
                        >
                          {school.begin}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item px-0">
                  <div
                    class="d-flex justify-content-between
                      align-items-center"
                  >
                    <div class="d-flex align-items-center">
                      <i class="bi bi-calendar-event fs-4 text-primary"></i>
                      <div class="ms-2">
                        <h5 class="mb-0 text-body">End Date</h5>
                      </div>
                    </div>
                    <div class="">
                      <div>
                        <p
                          class="text-dark mb-0
                              fw-semi-bold"
                        >
                          {school.end}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item  px-0">
                  <div
                    class="d-flex justify-content-between
                  align-items-center"
                  >
                    <div class="d-flex align-items-center">
                      <i class="bi bi-card-heading fs-4 text-primary"></i>
                      <div class="ms-2">
                        <h5 class="mb-0 text-body">Promo Title</h5>
                      </div>
                    </div>
                    <div class="">
                      <div>
                        <p
                          class="text-dark mb-0
                            fw-semi-bold"
                        >
                          {school.title.replace("?", "₦")}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm btn-outline-dark"
                id={school.token}
                onClick={(e) => {
                  End(e.target.id);
                }}
              >
                End Prome
              </button>

              {school.status_code != "LIVE" ? (
                <>
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-danger"
                    id={school.token}
                    onClick={(e) => {
                      Delete(e.target.id);
                    }}
                  >
                    Delete
                  </button>

                  <button
                    type="button"
                    class="btn btn-sm btn-outline-success"
                    id={school.token}
                    onClick={(e) => {
                      Live(e.target.id);
                    }}
                  >
                    Make Live
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        );
      });
    } else {
      return (
        <div
          className="col-md-12 text-center py-5 mt-5"
          onMouseMove={fetchData}
          onTouchMove={fetchData}
        >
          <div class="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>

          <h4 className="mt-6 h5 text-dark">Touch Screen With Mouse</h4>
        </div>
      );
    }
  };

  return (
    <>
      <nav class="navbar-vertical navbar bg-dark">
        <div class="nav-scroller">
          <ul class="navbar-nav flex-column py-10" id="sideNavbar">
            <li
              class="nav-item"
              onMouseMove={fetchData}
              onTouchMove={fetchData}
            >
              <a href="./#/promo#">
                <div class="p-3 fs-3 fw-bold text-left text-white">
                  Promo Lists <span class="badge bg-info fs-6">Refresh</span>
                </div>
              </a>
            </li>

            {renderTable()}
          </ul>
        </div>
      </nav>

      <div
        class="modal gd-example-modal-lg"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title h3 text-primary" id="exampleModalLabel">
                Promo List Preview
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <div class={state.alerts} role="alert">
                {state.message}
              </div>
              {renderPreview()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PromoTable;
