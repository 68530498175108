import React, { useState } from "react";

import axios from "axios";

import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

toast.configure();

const OrderApp = () => {
  const getusers = useSelector((state) => state.user.value);

  const getname = getusers.fullname;

  const getemail = getusers.email;

  let fname_1;

  if (getname) {
    fname_1 = getname.split(" ")[1];
  } else {
    fname_1 = "";
  }

  const pathname = window.location.hash;

  const Order = (clicked) => {
    axios({
      method: "post",
      url: "/v1/actions.php",
      data: {
        token: clicked,
        status: "ORDER",
        email: getemail,
        fullname: getname,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success(
            "Success: Proposal has been activated! Ready For dispatch to Agent"
          );
          fetchOrder();
        } else {
          toast.error("Error: Request Failed! Try again");
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check your connection..");
      });
  };

  const DeleteOrder = (clicked) => {
    axios({
      method: "post",
      url: "/v1/actions.php",
      data: {
        token: clicked,
        status: "DELETED ORDER",
        email: getemail,
        fullname: getname,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success("Success: Proposal has been deleted!");
          fetchOrder();
        } else {
          toast.error("Error: Request Failed! Try again");
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check your connection..");
      });
  };


  const [orders, setOrders] = useState(null);

  const handleChange = async (event) => {
    const searchinput = event.target.value;

    const response = await axios.post("./v1/orders.php", {
      email: getemail,
      q: searchinput,
    });

    const view = response.data;

    if (view.message === "failed") {
      toast.error("Error: Request Failed! Check your connection..");
    } else {
      setOrders(view);
    }
  };

  const fetchOrder = async () => {
    const response = await axios.post("./v1/orders.php", {
      email: getemail,
      q: null,
    });

    const view = response.data;

    if (view.message === "failed") {
      toast.error("Error: Request Failed! Check your connection..");
    } else {
      setOrders(view);
    }
  };

  const renderOrderTable = () => {
    if (orders == "") {
      return (
        <>
          <tr>
            <td colspan="5">
              <div className="col-md-12 py-5 mt-1 text-center">
                <img
                  src="../assets/images/background/empty.png"
                  alt="Gallery image 3"
                  class="gallery__img rounded-3"
                  style={{ width: "20%" }}
                />

                <h4 className="fs-3 fw-bold mt-4">
                  No School Has Been Uploaded...
                </h4>
              </div>
            </td>
          </tr>
        </>
      );
    } else if (orders) {
      return orders.map((school, index) => {
        const TimeDate = new Date(school.tm_date).toDateString();
        //console.log(school.tm_date)
        return (
          <>
            {school.status_code == "Delivered" ? (
              <></>
            ) : (
              <>
                <tr>
                  <td class="align-middle">
                    <div class="d-flex align-items-center">
                      <span class="avatar avatar-md avatar-warning">
                        <span class="avatar-initials rounded-circle">
                          {school.fullname
                            .split(" ")[0]
                            .charAt(0)
                            .toUpperCase() +
                            school.fullname
                              .split(" ")[1]
                              .charAt(0)
                              .toUpperCase()}
                        </span>
                      </span>
                      <div class="ms-3">
                        <h5 class="mb-0">
                          <a class="text-inherit">{school.fullname} </a>
                        </h5>
                      </div>
                    </div>
                  </td>
                  <td class="align-middle">
                    <a href="javascript:void(0)">{school.order_num}</a>
                  </td>
                  <td class="align-middle">{school.address}</td>
                  <td class="align-middle">
                    <a href={`mailto:${school.email}`}>{school.email}</a>
                    , <br /> <a href={`Tel:${school.phone}`}>{school.phone}</a>
                  </td>
                  <td class="align-middle">{school.amount}</td>
                  <td class="align-middle ">
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-dark mb-2"
                      id={school.token}
                      onClick={(e) => {
                        Order(e.target.id);
                      }}
                    >
                      Activate Order
                    </button>
                  </td>
                  <td class="align-middle ">
                    <a
                      target="_blank"
                      href={`https://api.whatsapp.com/send?phone=+234${school.phone.slice(
                        1
                      )}&text=Hello ${school.fullname}`}
                      class="btn btn-sm btn-outline-dark mb-2"
                    >
                      <i class="bi bi-whatsapp"></i>
                    </a>
                  </td>

                  <td class="align-middle ">
                    <a
                      href="javascript:void(0)"
                      id={school.token}
                      onClick={(e) => {
                        DeleteOrder(e.target.id);
                      }}
                      class="btn btn-sm btn-outline-danger mb-2"
                    >
                      <i class="bi bi-trash-fill"></i>
                    </a>
                  </td>
                </tr>
              </>
            )}
          </>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="5">
            <div
              className="col-md-12 text-center py-5 mt-5"
              onMouseMove={fetchOrder}
              onTouchMove={fetchOrder}
            >
              <div class="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>

              <h4 className="mt-6 h5 text-dark">Touch Screen With Mouse</h4>
            </div>
          </td>
        </tr>
      );
    }
  };

  const renderOrderTable_ = () => {
    if (orders == "") {
      return (
        <>
          <tr>
            <td colspan="5">
              <div className="col-md-12 py-5 mt-1 text-center">
                <img
                  src="../assets/images/background/empty.png"
                  alt="Gallery image 3"
                  class="gallery__img rounded-3"
                  style={{ width: "20%" }}
                />

                <h4 className="fs-3 fw-bold mt-4">
                  No School Has Been Uploaded...
                </h4>
              </div>
            </td>
          </tr>
        </>
      );
    } else if (orders) {
      return orders.map((school, index) => {
        const TimeDate = new Date(school.tm_date).toDateString();
        //console.log(school.tm_date)
        return (
          <>
            {school.status_code == "Pending" ? (
              <></>
            ) : (
              <>
                <tr>
                  <td class="align-middle">
                    <div class="d-flex align-items-center">
                      <span class="avatar avatar-md avatar-success">
                        <span class="avatar-initials rounded-circle">
                          {school.fullname
                            .split(" ")[0]
                            .charAt(0)
                            .toUpperCase() +
                            school.fullname
                              .split(" ")[1]
                              .charAt(0)
                              .toUpperCase()}
                        </span>
                      </span>
                      <div class="ms-3">
                        <h5 class="mb-0">
                          <a class="text-inherit">{school.fullname} </a>
                        </h5>
                      </div>
                    </div>
                  </td>
                  <td class="align-middle">
                    <a href="javascript:void(0)">{school.order_num}</a>
                  </td>
                  <td class="align-middle">{school.address}</td>
                  <td class="align-middle">
                    <a href={`mailto:${school.email}`}>{school.email}</a>
                    , <br /> <a href={`Tel:${school.phone}`}>{school.phone}</a>
                  </td>
                  <td class="align-middle">{school.amount}</td>
                  <td class="align-middle ">
                  <span class="badge bg-success">Completed</span>
                  </td>
                  <td class="align-middle ">
                    <a
                      target="_blank"
                      href={`https://api.whatsapp.com/send?phone=+234${school.phone.slice(
                        1
                      )}&text=Hello ${school.fullname}`}
                      class="btn btn-sm btn-outline-dark mb-2"
                    >
                      <i class="bi bi-whatsapp"></i>
                    </a>
                  </td>

                </tr>
              </>
            )}
          </>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="5">
            <div
              className="col-md-12 text-center py-5 mt-5"
              onMouseMove={fetchOrder}
              onTouchMove={fetchOrder}
            >
              <div class="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>

              <h4 className="mt-6 h5 text-dark">Touch Screen With Mouse</h4>
            </div>
          </td>
        </tr>
      );
    }
  };

  return (
    <div class="container-fluid p-4 py-12">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12 mb-2">
          <div
            class="d-lg-flex
          align-items-center justify-content-between"
          >
            <div class="mb-2 mb-lg-0">
              <h1 class="mb-0 h2 fw-bold">Order Lists</h1>
            </div>
            <div class="d-flex align-items-center">
              <Link
                to="/promo"
                class="btn btn-icon fs-3 btn-light border border-2 rounded-circle btn-dashed ms-2"
              >
                +
              </Link>
            </div>
          </div>
        </div>
      </div>

      {pathname === "#/orders_1" ? (
        <>
          <div class="row">
            <div class="col-12 mb-4">
              <ul class="nav nav-lb-tab">
                <li class="nav-item ms-0 me-3">
                  <Link to="/orders" class="nav-link">
                    Pending Orders{" "}
                    <span class="badge bg-warning text-dark">Pending</span>
                  </Link>
                </li>
                <li class="nav-item mx-3">
                  <Link to="/orders_1" class="nav-link active">
                    Completed Orders{" "}
                    <span class="badge bg-success text-white">Completed</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </>
      ) : (
        <>
          <div class="row">
            <div class="col-12 mb-4">
              <ul class="nav nav-lb-tab">
                <li class="nav-item ms-0 me-3">
                  <Link to="/orders" class="nav-link active">
                    Pending Orders{" "}
                    <span class="badge bg-warning text-dark">Pending</span>
                  </Link>
                </li>
                <li class="nav-item mx-3">
                  <Link to="/orders_1" class="nav-link">
                    Completed Orders{" "}
                    <span class="badge bg-success text-white">Completed</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </>
      )}

      <div class="row">
        <div class="col-4 mb-5">
          <form class="d-flex align-items-center">
            <span class="position-absolute ps-3 search-icon">
              <i class="fe fe-search"></i>
            </span>
            <input
              type="search"
              class="form-control form-control-md ps-6"
              placeholder="Search orders table lists"
              onChange={handleChange}
            />
          </form>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="mb-0 text-dark">List of orders</h4>
            </div>

            <div class="table-responsive overflow-y-hidden">
              <table class="table mb-10 text-nowrap table-hover table-borderless">
                <thead class="table-white">
                  <tr>
                  <th scope="col" class="border-top-0">
                        Agent Name
                      </th>
                      <th scope="col" class="border-top-0">
                        Order No.
                      </th>
                      <th scope="col" class="border-top-0 ">
                        Address
                      </th>
                      <th scope="col" class="border-top-0 ">
                        Contact
                      </th>
                      <th scope="col" class="border-top-0 ">
                        Amount
                      </th>
                      <th scope="col" class="border-top-0 "></th>
                  </tr>
                </thead>
                {pathname === "#/orders_1" ? (
                  <tbody>{renderOrderTable_()}</tbody>
                ) : (
                  <tbody>{renderOrderTable()}</tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderApp;
