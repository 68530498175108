import React, { useState } from "react";

import axios from "axios";

import { useSelector } from "react-redux";

import { Link } from "react-router-dom";

const Notify = () => {
  const getusers = useSelector((state) => state.user.value);

  const getemail = getusers.email;

  const [schools, setSchools] = useState(null);

  const [message, setMessage] = useState("");

  const fetchData = async () => {
    const response = await axios.post("./v1/notify.php", { email: getemail });

    const view = response.data;

    if (view.message === "failed") {
      setMessage(view.message);
    } else {
      setSchools(view);
    }
  };

  const renderTable = () => {
    if (message === "failed") {
      return <></>;
    } else if (schools) {
      return schools.map((school, index) => {
        //const TimeDate = new Date(school.tm_date).toDateString();
        //console.log(school.tm_date)
        return (
          <>
            <li class="list-group-item px-0 pt-0 border-0 pb-6">
              <div class="row position-relative">
                <div class="col-auto">
                  <div class="icon-shape icon-md bg-light-primary text-primary rounded-circle">
                    {school.title === "Admin Login System" ? (
                      <>
                        <i class="fe fe-shield"></i>
                      </>
                    ) : school.title === "Payouts System" ? (
                      <>
                        <i class="fe fe-credit-card"></i>
                      </>
                    ) : school.title === "Promo System" ? (
                      <>
                        <i class="fe fe-message-square"></i>
                      </>
                    ) : school.title === "Chat System" ? (
                      <>
                        <i class="fe fe-mail"></i>
                      </>
                    ) : (
                      <>
                        <i class="fe fe-check"></i>
                      </>
                    )}
                  </div>
                </div>
                <div class="col ms-n3">
                  <h4 class="mb-0 h5">{school.title}</h4>
                  <p class="mb-0 fs-6 text-body">{school.message}</p>
                </div>
                <div class="col-auto">
                  <span class="text-muted fs-6">{school.date_},</span>
                </div>
              </div>
            </li>
          </>
        );
      });
    } else {
      return (
        <div className="col-md-12 text-center py-5 mt-5">
          <div class="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>

          <h4 className="mt-6 h5 text-dark">Touch Screen With Mouse</h4>
        </div>
      );
    }
  };

  return (
    <div class="card" onMouseMove={fetchData} onTouchMove={fetchData}>
      <div class="card-header card-header-height d-flex justify-content-between align-items-center">
        <div>
          <h4 class="mb-0">Recent Activity</h4>
        </div>
        <div>
          <Link to="/notifications" class="">
            View All
          </Link>
        </div>
      </div>

      <div class="card-body">
        <ul class="list-group list-group-flush list-timeline-activity">
          {renderTable()}
        </ul>
      </div>
    </div>
  );
};

export default Notify;
