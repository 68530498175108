import React from "react";

import { Link } from "react-router-dom";

const Sidebar = () => {
  return (
    <nav class="navbar-vertical navbar">
      <div class="nav-scroller">
        <a class="navbar-brand">
          <img
            src="../assets/images/brand/logo/smfreelance.png"
            width="200"
            height="200"
            alt=""
          />
        </a>

        <ul class="navbar-nav flex-column" id="sideNavbar">
          <li class="nav-item">
            <div class="navbar-heading">Menu</div>
          </li>

          <li class="nav-item">
            <Link to="/home" class="nav-link">
              <i class="nav-icon fe fe-home me-2"></i> Dashboard
            </Link>
          </li>

          <li class="nav-item">
            <Link to="/users" class="nav-link">
              <i class="nav-icon fe fe-users me-2"></i> All Users
            </Link>
          </li>

          <li class="nav-item">
            <Link to="/schools" class="nav-link">
              <i class="nav-icon bi bi-bank2 me-2"></i> All Schools
            </Link>
          </li>

          <li class="nav-item">
            <Link to="/orders" class="nav-link">
              <i class="nav-icon bi bi-cart-check me-2"></i> Orders
            </Link>
          </li>

          <li class="nav-item">
            <Link to="/payouts" class="nav-link">
              <i class="nav-icon bi bi-wallet-fill me-2"></i> Payouts
            </Link>
          </li>

          <li class="nav-item">
            <div class="navbar-heading">Apps</div>
          </li>

          <li class="nav-item">
            <a
              href="https://app.smartsupp.com/app/dashboard/conversations/covUYuMz62HJz"
              target="_blank"
              class="nav-link"
            >
              <i class="nav-icon fe fe-message-square me-2"></i> Chat
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link">
              <i class="nav-icon mdi mdi-credit-card me-2"></i>
              Payment
            </a>
          </li>

          <li class="nav-item">
            <Link to="/tracker" class="nav-link">
              <i class="nav-icon bi bi-app-indicator me-2"></i>
              Tracker <span class="badge bg-success ms-2">Ver 1.0</span>
            </Link>
          </li>
          
          <li class="nav-item">
            <div class="nav-divider"></div>
          </li>

          <li class="nav-item">
            <div class="navbar-heading">Account</div>
          </li>

          <li class="nav-item">
            <Link to="/profile" class="nav-link">
              <i class="nav-icon fe fe-clipboard me-2"></i> Profile
            </Link>
          </li>

          <li class="nav-item">
            <Link to="/settings" class="nav-link">
              <i class="nav-icon fe fe-git-pull-request me-2"></i> Settings
              <span class="badge bg-primary ms-2">2FA</span>
            </Link>
          </li>
        </ul>

        <div class="card bg-dark-primary shadow-none text-center mx-4 my-8">
          <div class="card-body py-6">
            <img src="../../assets/images/background/giftbox.png" alt="" />
            <div class="mt-4">
              <h5 class="text-white">Welcome to dashboard</h5>
              <p class="text-white-50 fs-6">
                We would provide you documentations to the App workings and
                usage. Click button below
              </p>
              <a
                href="./#/help-center"
                target="_blank"
                class="btn btn-white fs-6 btn-sm mt-2"
              >
                Help Center <i class="fs-6 bi bi-box-arrow-in-up-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
