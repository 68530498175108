import React, { useState } from "react";

import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import PromoList from "./PromoList";

import { useQuill } from "react-quilljs";

import "quill/dist/quill.snow.css";

import axios from "axios";

const PromoApp = () => {
  const getusers = useSelector((state) => state.user.value);

  const getemail = getusers.email;

  const getname = getusers.fullname;

  const { quill, quillRef } = useQuill();

  //console.log(quill); // undefined > Quill Object
  //console.log(quillRef);

  const [title, setTitle] = useState("");
  const [begin, setBegin] = useState("");
  const [end, setEnd] = useState("");
  const [message, setMessage] = useState("");

  React.useEffect(() => {
    if (quill) {
      quill.on("text-change", (delta, oldDelta, source) => {
        //console.log('Text change!');
        //console.log(quill.getText());
        //console.log(quill.getContents()); // Get delta contents
        //console.log(quill.root.innerHTML); // Get innerHTML using quill
        //console.log(quillRef.current.firstChild.innerHTML);

        setMessage(quill.getText());
      });
    }
  }, [quill]);

  const [state, setState] = React.useState({
    buttonname: "Send New Promo",
    color: "",
    alerts: "",
    info: "",
    isSubmit: "",
  });

  const onSubmit = (b) => {
    b.preventDefault();

    const users = {
      fullname: getname,
      email: getemail,
      message: message,
      title: title,
      begin: begin,
      end: end,
    };

    console.log(users);

    axios({
      method: "post",
      url: "/v1/promo.php",
      data: users,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          setState({
            statusin: true,
            alerts:
              "alert alert-success fs-4 mt-3 mb-3 alert-dismissible fade show",
            info: "Promo was sent successfully. 👋",
            isSubmit: "spinner-grow spinner-grow-sm",
            buttonname: "Promo Has Been Sent",
          });

          setTimeout(function () {
            setState({
                statusin: false,
                buttonname: "Promo Has Been Sent",
              });
          }, 6000);

          window.scrollTo(10, 30);
        } else if (response.data.message === "exist") {
          setState({
            statusin: false,
            alerts:
              "alert alert-warning text-dark fs-4 mt-3 mb-3 alert-dismissible fade show",
            info: "Promo currently running. Please go and end promo first! 👋",
            isSubmit: "spinner-grow spinner-grow-sm",
            buttonname: "Request Not Processed",
          });

          window.scrollTo(10, 30);
        } else {
          setState({
            statusin: false,
            alerts:
              "alert alert-danger fs-4 mt-3 mb-3 alert-dismissible fade show",
            info: "Request Failed. Looks like network is down ☹️",
            isSubmit: "spinner-grow spinner-grow-sm",
            buttonname: "Request Failed",
          });

          window.scrollTo(10, 30);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div class="container-fluid p-4 py-12">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12 mb-2">
          <div
            class="d-lg-flex
          align-items-center justify-content-between"
          >
            <div class="mb-2 mb-lg-0">
              <h1 class="mb-0 h2 fw-bold">
                Promo System
              </h1>
            </div>

            <div class="mb-2 mb-lg-0">
              <h1 class="mb-0 h4 fw-semi-bold">
                <Link to="/home" className="text-dark">
                  <i class="bi bi-arrow-left text-success"></i>
                  {" "}Go To Dashboard
                </Link>
              </h1>
            </div>

          </div>
        </div>
      </div>

      <div class="row py-10">
        <div class="col-md-5 col-xl-5 col-5">
          <div class="row">
            {state.statusin ? (
              <>
                <div className="col-md-12 text-center py-5 mt-5">
                  <div class="lds-roller">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <PromoList getemail={getemail} />
              </>
            )}
          </div>
        </div>

        <div class="col-md-7 col-xl-7 col-7">
          <div class="card">
            <div className={state.alerts} role="alert">
              {state.info}
            </div>
            <div class="card-header">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <h4 class="mb-0">Promo Container System</h4>
                </div>
              </div>
            </div>

            <div class="card-body">
              <form onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label for="name" className="form-label">
                      Promo Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Promo Title"
                      required
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label for="name" className="form-label">
                      Start Date
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Enter Promo Title"
                      required
                      onChange={(e) => {
                        setBegin(e.target.value);
                      }}
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label for="name" className="form-label">
                      End Date
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Enter Promo Title"
                      required
                      onChange={(e) => {
                        setEnd(e.target.value);
                      }}
                    />
                  </div>

                  <div className="col-md-12 mb-3">
                    <label for="name" className="form-label">
                      Promo Content
                    </label>
                    <div style={{ width: 500, height: 200 }}>
                      <div ref={quillRef} />
                    </div>
                  </div>
                </div>

                <div className="col-md-6 pt-10">
                  <div class="">
                    <button type="submit" class="btn btn-success">
                      <span>{state.buttonname}</span>{" "}
                      <span className={state.isSubmit}></span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromoApp;
