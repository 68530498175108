import React, { useState } from "react";

import axios from "axios";

import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import CustomScroll from "react-custom-scroll";

toast.configure();

const UserApp = () => {
  const getusers = useSelector((state) => state.user.value);

  const getname = getusers.fullname;

  const getemail = getusers.email;

  let fname_1;

  if (getname) {
    fname_1 = getname.split(" ")[1];
  } else {
    fname_1 = "";
  }

  const [profile, setProfile] = useState(null);

  const Preview = async (clicked) => {
    const response = await axios.post("./v1/users.php", {
      email: getemail,
      q: clicked,
    });

    const view = response.data;

    console.table(view);

    if (view.message === "failed") {
      toast.error("Error: Request Failed! Check your connection..");
    } else {
      toast.success("Success: Showing profile of "+clicked);
      setProfile(view);
      window.scrollTo(50, 50);
    }
  };

  const [schools, setSchools] = useState(null);

  const handleChange = async (event) => {
    const searchinput = event.target.value;

    const response = await axios.post("./v1/users.php", {
      email: getemail,
      q: searchinput,
    });

    const view = response.data;

    if (view.message === "failed") {
      toast.error("Error: Request Failed! Check your connection..");
    } else {
      setSchools(view);
    }
  };

  const fetchData = async () => {
    const response = await axios.post("./v1/users.php", {
      email: getemail,
      q: null,
    });

    const view = response.data;

    if (view.message === "failed") {
      toast.error("Error: Request Failed! Check your connection..");
    } else {
      setSchools(view);
    }
  };

  const renderTable = () => {
    if (schools == "") {
      return (
        <>
          <tr>
            <td colspan="3">
              <div className="col-md-12 py-5 mt-1 text-center">
                <img
                  src="../assets/images/background/empty.png"
                  alt="Gallery image 3"
                  class="gallery__img rounded-3"
                  style={{ width: "20%" }}
                />

                <h4 className="fs-3 fw-bold mt-4">No Result Found...</h4>
              </div>
            </td>
          </tr>
        </>
      );
    } else if (schools) {
      return schools.map((school, index) => {
        const TimeDate = new Date(school.tm_date).toDateString();
        //console.log(school.tm_date)
        return (
          <>
            {school.status_code == "Completed" ? (
              <></>
            ) : (
              <>
                <tr>
                  <td class="align-middle">
                    <div class="d-flex align-items-center">
                      <span class="avatar avatar-md avatar-danger">
                        <span class="avatar-initials rounded-circle">
                          {school.fullname
                            .split(" ")[0]
                            .charAt(0)
                            .toUpperCase() +
                            school.fullname
                              .split(" ")[1]
                              .charAt(0)
                              .toUpperCase()}
                        </span>
                      </span>
                      <div class="ms-3">
                        <h5 class="mb-0">
                          <a class="text-inherit">{school.fullname} </a>
                        </h5>
                      </div>
                    </div>
                  </td>
                  <td class="align-middle">
                    <a href={`mailto:${school.email}`}>{school.email}</a>
                    , <br /> <a href={`Tel:${school.phone}`}>{school.phone}</a>
                  </td>
                  <td class="align-middle">
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-dark mb-2"
                      id={school.email}
                      onClick={(e) => {
                        Preview(e.target.id);
                      }}
                    >
                      Access User
                    </button>
                  </td>
                </tr>
              </>
            )}
          </>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="3">
            <div
              className="col-md-12 text-center py-5 mt-5"
              onMouseMove={fetchData}
              onTouchMove={fetchData}
            >
              <div class="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>

              <h4 className="mt-6 h5 text-dark">Touch Screen With Mouse</h4>
            </div>
          </td>
        </tr>
      );
    }
  };

  const renderProfile = () => {
    if (profile == "") {
      return (
        <>
          <div className="col-md-12 py-5 mt-1 text-center">
            <img
              src="../assets/images/background/empty.png"
              alt="Gallery image 3"
              class="gallery__img rounded-3"
              style={{ width: "20%" }}
            />

            <h4 className="fs-3 fw-bold mt-4">No Result Found...</h4>
          </div>
        </>
      );
    } else if (profile) {
      return profile.map((school, index) => {
        const TimeDate = new Date(school.tm_date).toDateString();
        //console.log(school.tm_date)
        return <>
         <div className="col-md-4">
          <div class="card">
            <div class="card-header">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <h4 class="h3 fw-bold text-success mb-0"> {school.fullname} Profile</h4>
                </div>
              </div>
            </div>

            <div class="card-body">
              <ul class="list-group list-group-flush">
                <li class="list-group-item px-0">
                  <div
                    class="d-flex justify-content-between
                      align-items-center"
                  >
                    <div class="d-flex align-items-center">
                      <i class="bi bi-card-heading fs-4 text-primary"></i>
                      <div class="ms-2">
                        <h5 class="mb-0 text-body">Address</h5>
                      </div>
                    </div>
                    <div class="ms-5">
                      <div>
                        <p
                          class="text-dark mb-0
                              fw-semi-bold"
                        >{school.address}</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item px-0">
                  <div
                    class="d-flex justify-content-between
                      align-items-center"
                  >
                    <div class="d-flex align-items-center">
                      <i class="bi bi-calendar-event fs-4 text-primary"></i>
                      <div class="ms-2">
                        <h5 class="mb-0 text-body">Job</h5>
                      </div>
                    </div>
                    <div class="ms-1">
                      <div>
                        <p
                          class="text-dark mb-0
                              fw-semi-bold"
                        >{school.job}</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item  px-0">
                  <div
                    class="d-flex justify-content-between
                  align-items-center"
                  >
                    <div class="d-flex align-items-center">
                      <i class="bi bi-calendar-event  fs-4 text-primary"></i>
                      <div class="ms-2">
                        <h5 class="mb-0 text-body">Birth</h5>
                      </div>
                    </div>
                    <div class="">
                      <div>
                        <p
                          class="text-dark mb-0
                            fw-semi-bold"
                        >{school.birth}</p>
                      </div>
                    </div>
                  </div>
                </li>

                <li class="list-group-item  px-0">
                  <div
                    class="d-flex justify-content-between
                  align-items-center"
                  >
                    <div class="d-flex align-items-center">
                      <i class="bi bi-card-heading fs-4 text-primary"></i>
                      <div class="ms-2">
                        <h5 class="mb-0 text-body">Total Earnings</h5>
                      </div>
                    </div>
                    <div class="">
                      <div>
                        <p
                          class="text-dark mb-0
                            fw-semi-bold"
                        >₦{school.total}</p>
                      </div>
                    </div>
                  </div>
                </li>

                <li class="list-group-item  px-0">
                  <div
                    class="d-flex justify-content-between
                  align-items-center"
                  >
                    <div class="d-flex align-items-center">
                      <i class="bi bi-card-heading fs-4 text-primary"></i>
                      <div class="ms-2">
                        <h5 class="mb-0 text-body">No. Of Schools</h5>
                      </div>
                    </div>
                    <div class="">
                      <div>
                        <p
                          class="text-dark mb-0
                            fw-semi-bold"
                        >{school.schools}</p>
                      </div>
                    </div>
                  </div>
                </li>

                <li class="list-group-item  px-0">
                  <div
                    class="d-flex justify-content-between
                  align-items-center"
                  >
                    <div class="d-flex align-items-center">
                      <i class="bi bi-card-heading fs-4 text-primary"></i>
                      <div class="ms-2">
                        <h5 class="mb-0 text-body">Bank No.</h5>
                      </div>
                    </div>
                    <div class="">
                      <div>
                        <p
                          class="text-dark mb-0
                            fw-semi-bold"
                        >{school.bank}</p>
                      </div>
                    </div>
                  </div>
                </li>

                <li class="list-group-item  px-0">
                  <div
                    class="d-flex justify-content-between
                  align-items-center"
                  >
                    <div class="d-flex align-items-center">
                      <i class="bi bi-card-heading fs-4 text-primary"></i>
                      <div class="ms-2">
                        <h5 class="mb-0 text-body">Date Joined</h5>
                      </div>
                    </div>
                    <div class="">
                      <div>
                        <p
                          class="text-dark mb-0
                            fw-semi-bold"
                        >{school.trn_date}</p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        </>;
      });
    } else {
      return (
        <div
          className="col-md-4 text-center py-5 mt-5"
        >
          
          <h4 className="mt-6 h5 text-dark">Profile Preview Would Reflect Here!</h4>
        </div>
      );
    }
  };

  return (
    <div class="container-fluid p-4 py-12">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12 mb-2">
          <div
            class="d-lg-flex
          align-items-center justify-content-between"
          >
            <div class="mb-2 mb-lg-0">
              <h1 class="mb-0 h2 fw-bold">Users List</h1>
            </div>
            <div class="d-flex align-items-center">
              <Link
                to="/promo"
                class="btn btn-icon fs-3 btn-light border border-2 rounded-circle btn-dashed ms-2"
              >
                +
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 mb-4">
          <ul class="nav nav-lb-tab">
            <li class="nav-item ms-0 me-3">
              <Link to="/home" class="nav-link">
                Dashboard
              </Link>
            </li>
            <li class="nav-item mx-3">
              <Link to="/task" class="nav-link">
                Task
              </Link>
            </li>
            <li class="nav-item mx-3">
              <Link to="/users" class="nav-link active">
                Users
              </Link>
            </li>
            <li class="nav-item mx-3">
              <Link to="/promos" class="nav-link">
                All Promo
              </Link>
            </li>
            <li class="nav-item mx-3">
              <Link to="/files" class="nav-link">
                Files
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-4 mb-5">
          <form class="d-flex align-items-center">
            <span class="position-absolute ps-3 search-icon">
              <i class="fe fe-search"></i>
            </span>
            <input
              type="search"
              class="form-control form-control-md ps-6"
              placeholder="Search users table"
              onChange={handleChange}
            />
          </form>
        </div>
      </div>

      <div class="row">
        <div class="col-md-8">
          <div class="card">
            <div class="card-header">
              <h4 class="mb-0 text-dark">Users Table</h4>
            </div>

            <CustomScroll>
              <div class="table-responsive overflow-y-hidden">
                <table class="table mb-10 text-nowrap table-hover table-borderless">
                  <thead class="table-white">
                    <tr>
                      <th scope="col" class="border-top-0">
                        Agent Name
                      </th>
                      <th scope="col" class="border-top-0 ">
                        Contact
                      </th>
                      <th scope="col" class="border-top-0 ">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>{renderTable()}</tbody>
                </table>
              </div>
            </CustomScroll>
          </div>
        </div>

        {renderProfile()}
       
      </div>
    </div>
  );
};

export default UserApp;
