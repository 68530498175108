import React, { useState } from "react";

import axios from "axios";

import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

toast.configure();

const AlertsApp = () => {
  const getusers = useSelector((state) => state.user.value);

  const getname = getusers.fullname;

  const getemail = getusers.email;

  let fname_1;

  if (getname) {
    fname_1 = getname.split(" ")[1];
  } else {
    fname_1 = "";
  }

  const [schools, setOrders] = useState(null);
  const [message, setMessage] = useState("");

  const handleChange = async (event) => {
    const searchinput = event.target.value;

    const response = await axios.post("./v1/cli_notify.php", {
      email: getemail,
      q: searchinput,
    });

    const view = response.data;

    if (view.message === "failed") {
      toast.error("Error: Request Failed! Check your connection..");
      setMessage(view.message);
    } else {
      setOrders(view);
    }
  };

  const fetchOrder = async () => {
    const response = await axios.post("./v1/cli_notify.php", {
      email: getemail,
      q: '2022',
    });

    const view = response.data;

    if (view.message === "failed") {
      toast.error("Error: Request Failed! Check your connection..");
      setMessage(view.message);
    } else {
      setOrders(view);
    }
  };

  const renderTable = () => {
    if (message === "failed") {
      return <></>;
    } else if (schools) {
      return schools.map((school, index) => {
        //const TimeDate = new Date(school.tm_date).toDateString();
        //console.log(school.tm_date)
        return (
          <>
            <tr>
              <td class="align-middle">
                <div class="fw-semi-bold">
                  <div class="icon-shape icon-md bg-light-primary text-primary rounded-circle me-2">
                    {school.title === "Admin Login System" ? (
                      <>
                        <i class="fe fe-shield"></i>
                      </>
                    ) : school.title === "Payouts System" ? (
                      <>
                        <i class="fe fe-credit-card"></i>
                      </>
                    ) : school.title === "Promo System" ? (
                      <>
                        <i class="fe fe-message-square"></i>
                      </>
                    ) : school.title === "Chat System" ? (
                      <>
                        <i class="fe fe-mail"></i>
                      </>
                    ) : (
                      <>
                        <i class="fe fe-check"></i>
                      </>
                    )}
                  </div>
                  <>{school.title}</>
                </div>
              </td>

              <td class="align-middle">{school.message}</td>

              <td class="align-middle">{school.device_type}</td>

              <td class="align-middle">{school.date_time}</td>
            </tr>
          </>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="5">
            <div className="col-md-12 text-center py-5 mt-5">
              <div class="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>

              <h4 className="mt-6 h5 text-dark">Touch Screen With Mouse</h4>
            </div>
          </td>
        </tr>
      );
    }
  };

  return (
    <div class="container-fluid p-4 py-12">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12 mb-2">
          <div
            class="d-lg-flex
          align-items-center justify-content-between"
          >
            <div class="mb-2 mb-lg-0">
              <h1 class="mb-0 h2 fw-bold">Agents Activities</h1>
            </div>
            <div class="d-flex align-items-center">
              <Link
                to="/promo"
                class="btn btn-icon fs-3 btn-light border border-2 rounded-circle btn-dashed ms-2"
              >
                +
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div class="row py-5">
        <div class="col-md-12 mb-5">
          <form class="d-flex align-items-center">
            <span class="position-absolute ps-3 search-icon">
              <i class="fe fe-search"></i>
            </span>
            <input
              type="search"
              class="form-control form-control-md ps-6"
              placeholder="Search orders table lists"
              onChange={handleChange}
            />
          </form>
        </div>
      </div>

      <div class="row" onMouseMove={fetchOrder} onTouchMove={fetchOrder}>
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="mb-0 text-dark">All Activities</h4>
            </div>

            <div class="table-responsive overflow-y-hidden">
              <table class="table mb-10 text-nowrap table-hover table-borderless">
                <thead class="table-white">
                  <tr>
                    <th scope="col" class="border-top-0">
                      Syetem Type
                    </th>
                    <th scope="col" class="border-top-0">
                      Details
                    </th>
                    <th scope="col" class="border-top-0 ">
                      Device
                    </th>
                    <th scope="col" class="border-top-0 ">
                      Time & Date
                    </th>
                  </tr>
                </thead>
                <tbody>{renderTable()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertsApp;
