import React, { useState } from "react";

import axios from "axios";

import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import CustomScroll from "react-custom-scroll";

toast.configure();

const TaskApp = () => {

  const getusers = useSelector((state) => state.user.value);

  const getname = getusers.fullname;

  const getemail = getusers.email;

  let fname_1;

  const Payout = (clicked) => {
    axios({
      method: "post",
      url: "/v1/actions.php",
      data: {
        token: clicked,
        status: "PAYOUT",
        email: getemail,
        fullname: getname,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success(
            "Success: Payment has been updated to Agent, Successfully!"
          );
          fetchInfo(clicked);
        } else {
          toast.error("Error: Request Failed! Try again");
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check your connection..");
      });
  };

  const Order = (clicked) => {
    axios({
      method: "post",
      url: "/v1/actions.php",
      data: {
        token: clicked,
        status: "ORDER",
        email: getemail,
        fullname: getname,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success(
            "Success: Proposal has been activated! Ready For dispatch to Agent"
          );
          fetchOrder();
        } else {
          toast.error("Error: Request Failed! Try again");
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check your connection..");
      });
  };

  const DeleteOrder = (clicked) => {
    axios({
      method: "post",
      url: "/v1/actions.php",
      data: {
        token: clicked,
        status: "DELETED ORDER",
        email: getemail,
        fullname: getname,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success("Success: Proposal has been deleted!");
          fetchOrder();
        } else {
          toast.error("Error: Request Failed! Try again");
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check your connection..");
      });
  };

  const Activate = (clicked) => {
    axios({
      method: "post",
      url: "/v1/actions.php",
      data: {
        token: clicked,
        status: "SCHOOL",
        email: getemail,
        fullname: getname,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success("Success: School has been activated!");
          fetchData();
        } else {
          toast.error("Error: Request Failed! Try again");
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check your connection..");
      });
  };

  const Delete = (clicked) => {
    axios({
      method: "post",
      url: "/v1/actions.php",
      data: {
        token: clicked,
        status: "DELETED SCHOOL",
        email: getemail,
        fullname: getname,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success("Success: School has been deleted!");
          fetchData();
        } else {
          toast.error("Error: Request Failed! Try again");
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check your connection..");
      });
  };

  if (getname) {
    fname_1 = getname.split(" ")[1];
  } else {
    fname_1 = "";
  }

  const [payout, setPayout] = useState(null);

  const [message3, setMessage3] = useState("");

  const fetchInfo = async (clicked) => {
    const response = await axios.post("./v1/user_payout.php", {
      token: clicked,
    });

    const view = response.data;

    if (view.message === "success") {
      setPayout(view);
    } else {
      toast.error("Error: Request Failed! Try again");
    }
  };

  const renderUserInfo = () => {
    if (payout == "") {
      return (
        <>
          <div className="col-md-12 py-5 mt-1 text-center">
            <img
              src="../assets/images/background/empty.png"
              alt="Gallery image 3"
              class="gallery__img rounded-3"
              style={{ width: "20%" }}
            />

            <h4 className="fs-3 fw-bold mt-4">
              No Results Found...
            </h4>
          </div>
        </>
      );
    } else if (payout) {
      return (
        <>
          {payout.message !== "success" ? (
            <></>
          ) : (
            <>
              <div class="card-body">
                <p className="mb-4 text-dark fs-4">
                  Agent Name: <a href="javascript:void(0)">{payout.fullname}</a>
                  . Agent Address:{" "}
                  <a href="javascript:void(0)">{payout.address}</a>. Agent
                  Contacts:{" "}
                  <a href={`mailto:${payout.email}`}>{payout.email}</a>,{" "}
                  <a href={`Tel:${payout.phone}`}>{payout.phone}</a>
                </p>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item px-0">
                    <div
                      class="d-flex justify-content-between
                      align-items-center"
                    >
                      <div class="d-flex align-items-center">
                        <i class="bi bi-gift-fill fs-4 text-primary"></i>
                        <div class="ms-2">
                          <h5 class="mb-0 text-body">Bonus Pay</h5>
                        </div>
                      </div>
                      <div class="">
                        <div>
                          <p
                            class="text-dark mb-0
                              fw-semi-bold"
                          >
                            ₦{payout.bonus}
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item px-0">
                    <div
                      class="d-flex justify-content-between
                      align-items-center"
                    >
                      <div class="d-flex align-items-center">
                        <i class="bi bi-cash-coin fs-4 text-primary"></i>
                        <div class="ms-2">
                          <h5 class="mb-0 text-body">Earnings</h5>
                        </div>
                      </div>
                      <div class="">
                        <div>
                          <p
                            class="text-dark mb-0
                              fw-semi-bold"
                          >
                            ₦{payout.revenue}
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item  px-0">
                    <div
                      class="d-flex justify-content-between
                  align-items-center"
                    >
                      <div class="d-flex align-items-center">
                        <i class="bi bi-currency-dollar fs-4 text-primary"></i>
                        <div class="ms-2">
                          <h5 class="mb-0 text-body">Smart Invest</h5>
                        </div>
                      </div>
                      <div class="">
                        <div>
                          <p
                            class="text-dark mb-0
                            fw-semi-bold"
                          >
                            ₦{payout.invest}
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item  px-0">
                    <div
                      class="d-flex justify-content-between
                  align-items-center"
                    >
                      <div class="d-flex align-items-center">
                        <i class="bi bi-credit-card-2-front-fill fs-4 text-danger"></i>
                        <div class="ms-2">
                          <h5 class="mb-0 text-body">initialised Payout</h5>
                        </div>
                      </div>
                      <div class="">
                        <div>
                          <p
                            class="text-danger mb-0 fs-3
                            fw-semi-bold"
                          >
                            ₦{payout.revenue}
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item  px-0">
                    <div
                      class="d-flex justify-content-between
                  align-items-center"
                    >
                      <div class="d-flex align-items-center">
                        <img
                          src="../assets/images/brand/zenith.png"
                          width="15%"
                          alt=""
                        />
                        <div class="ms-2">
                          <h3 class="mb-0 text-body">
                            Account No. {payout.zenith_acc}
                          </h3>
                        </div>
                      </div>
                      <div class="">
                        <div>
                          <button
                            type="button"
                            class="btn btn-sm btn-success"
                            data-bs-dismiss="modal" aria-label="Close"
                            id={payout.token}
                            onClick={(e) => {
                              Payout(e.target.id);
                            }}
                          >
                            <i class="bi bi-credit-card"></i> Verify Pay Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </>
          )}
        </>
      );
    } else {
      return (
        
            <div
              className="col-md-12 text-center py-5 mt-5"
              onMouseMove={fetchInfo}
              onTouchMove={fetchInfo}
            >
              <div class="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>

              <h4 className="mt-6 h5 text-dark">Touch Screen With Mouse</h4>
            </div>
      );
    }
  };

  const [schools, setSchools] = useState(null);

  const [message, setMessage] = useState("");

  const fetchData = async () => {
    const response = await axios.post("./v1/schools.php", { email: getemail, q: null });

    const view = response.data;

    if (view.message === "failed") {
      setMessage(view.message);
    } else {
      setSchools(view);
    }
  };

  const renderTable = () => {
    if (schools == "") {
      return (
        <>
          <tr>
            <td colspan="4">
              <div className="col-md-12 py-5 mt-1 text-center">
                <img
                  src="../assets/images/background/empty.png"
                  alt="Gallery image 3"
                  class="gallery__img rounded-3"
                  style={{ width: "20%" }}
                />

                <h4 className="fs-3 fw-bold mt-4">
                  No Results Found...
                </h4>
              </div>
            </td>
          </tr>
        </>
      );
    } else if (schools) {
      return schools.map((school, index) => {
        const TimeDate = new Date(school.tm_date).toDateString();
        //console.log(school.tm_date)
        return (
          <>
            {school.status_code == "Completed" ? (
              <></>
            ) : (
              <>
                <tr>
                  <td class="align-middle">
                    <div class="d-flex align-items-center">
                      <span class="avatar avatar-md avatar-danger">
                        <span class="avatar-initials rounded-circle">
                          {school.fullname
                            .split(" ")[0]
                            .charAt(0)
                            .toUpperCase() +
                            school.fullname
                              .split(" ")[1]
                              .charAt(0)
                              .toUpperCase()}
                        </span>
                      </span>
                      <div class="ms-3">
                        <h5 class="mb-0">
                          <a class="text-inherit">{school.fullname} </a>
                        </h5>
                      </div>
                    </div>
                  </td>
                  <td class="align-middle">
                    <a href="javascript:void(0)">{school.schoolname}</a>
                  </td>
                  <td class="align-middle">
                    <a href={`Tel:${school.schoolphone}`} className="text-info">
                      {school.schoolphone}
                    </a>
                  </td>
                  <td class="align-middle">{school.schoolpopulation}</td>
                  <td class="align-middle ">
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-dark mb-2"
                      id={school.token}
                      onClick={(e) => {
                        Activate(e.target.id);
                      }}
                    >
                      Activate Pay
                    </button>
                  </td>
                  <td class="align-middle ">
                    {" "}
                    <span class="dropdown dropstart">
                      <a
                        class="text-muted text-decoration-none"
                        href="#"
                        role="button"
                        id="DropdownOne"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fe fe-more-vertical"></i>
                      </a>
                      <span class="dropdown-menu" aria-labelledby="DropdownOne">
                        <span class="dropdown-header">Settings</span>
                        <Link to="#" class="dropdown-item">
                          Contact Agent (Coming soon)
                        </Link>
                        <a
                          class="dropdown-item"
                          href="javascript:void(0)"
                          id={school.token}
                          onClick={(e) => {
                            Delete(e.target.id);
                          }}
                        >
                          Delete School
                        </a>
                      </span>
                    </span>
                  </td>
                </tr>
              </>
            )}
          </>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="4">
            <div
              className="col-md-12 text-center py-5 mt-5"
              onMouseMove={fetchData}
              onTouchMove={fetchData}
            >
              <div class="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>

              <h4 className="mt-6 h5 text-dark">Touch Screen With Mouse</h4>
            </div>
          </td>
        </tr>
      );
    }
  };

  const [orders, setOrders] = useState(null);

  const [message1, setMessage1] = useState("");

  const fetchOrder = async () => {
    const response = await axios.post("./v1/orders.php", { email: getemail, q: null});

    const view = response.data;

    if (view.message === "failed") {
      setMessage1(view.message);
    } else {
      setOrders(view);
    }
  };

  const renderOrderTable = () => {
    if (orders == "") {
      return (
        <>
          <tr>
            <td colspan="5">
              <div className="col-md-12 py-5 mt-1 text-center">
                <img
                  src="../assets/images/background/empty.png"
                  alt="Gallery image 3"
                  class="gallery__img rounded-3"
                  style={{ width: "20%" }}
                />

                <h4 className="fs-3 fw-bold mt-4">
                  No Results Found...
                </h4>
              </div>
            </td>
          </tr>
        </>
      );
    } else if (orders) {
      return orders.map((school, index) => {
        const TimeDate = new Date(school.tm_date).toDateString();
        //console.log(school.tm_date)
        return (
          <>
            {school.status_code == "Delivered" ? (
              <></>
            ) : (
              <>
                <tr>
                  <td class="align-middle">
                    <div class="d-flex align-items-center">
                      <span class="avatar avatar-md avatar-success">
                        <span class="avatar-initials rounded-circle">
                          {school.fullname
                            .split(" ")[0]
                            .charAt(0)
                            .toUpperCase() +
                            school.fullname
                              .split(" ")[1]
                              .charAt(0)
                              .toUpperCase()}
                        </span>
                      </span>
                      <div class="ms-3">
                        <h5 class="mb-0">
                          <a class="text-inherit">{school.fullname} </a>
                        </h5>
                      </div>
                    </div>
                  </td>
                  <td class="align-middle">
                    <a href="javascript:void(0)">{school.order_num}</a>
                  </td>
                  <td class="align-middle">{school.address}</td>
                  <td class="align-middle">
                    <a href={`mailto:${school.email}`}>{school.email}</a>
                    , <br /> <a href={`Tel:${school.phone}`}>{school.phone}</a>
                  </td>
                  <td class="align-middle">{school.amount}</td>
                  <td class="align-middle ">
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-dark mb-2"
                      id={school.token}
                      onClick={(e) => {
                        Order(e.target.id);
                      }}
                    >
                      Activate Order
                    </button>
                  </td>
                  <td class="align-middle ">
                    <a
                      target="_blank"
                      href={`https://api.whatsapp.com/send?phone=+234${school.phone.slice(
                        1
                      )}&text=Hello ${school.fullname}`}
                      class="btn btn-sm btn-outline-dark mb-2"
                    >
                      <i class="bi bi-whatsapp"></i>
                    </a>
                  </td>

                  <td class="align-middle ">
                    <a
                      href="javascript:void(0)"
                      id={school.token}
                      onClick={(e) => {
                        DeleteOrder(e.target.id);
                      }}
                      class="btn btn-sm btn-outline-danger mb-2"
                    >
                      <i class="bi bi-trash-fill"></i>
                    </a>
                  </td>
                </tr>
              </>
            )}
          </>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="5">
            <div
              className="col-md-12 text-center py-5 mt-5"
              onMouseMove={fetchOrder}
              onTouchMove={fetchOrder}
            >
              <div class="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>

              <h4 className="mt-6 h5 text-dark">Touch Screen With Mouse</h4>
            </div>
          </td>
        </tr>
      );
    }
  };

  const [pays, setPays] = useState(null);

  const [message2, setMessage2] = useState("");

  const fetchPay = async () => {
    const response = await axios.post("./v1/payouts.php", { email: getemail, q: null });

    const view = response.data;

    if (view.message === "failed") {
      setMessage2(view.message);
    } else {
      setPays(view);
    }
  };

  const renderPayTable = () => {
    if (pays == "") {
      return (
        <>
          <tr>
            <td colspan="5">
              <div className="col-md-12 py-5 mt-1 text-center">
                <img
                  src="../assets/images/background/empty.png"
                  alt="Gallery image 3"
                  class="gallery__img rounded-3"
                  style={{ width: "20%" }}
                />

                <h4 className="fs-3 fw-bold mt-4">
                  No Results Found...
                </h4>
              </div>
            </td>
          </tr>
        </>
      );
    } else if (pays) {
      return pays.map((school, index) => {
        //const TimeDate = new Date(school.tm_date).toDateString();
        //console.log(school.tm_date)
        return (
          <>
            {school.status_code === "Completed" ? (
              <></>
            ) : (
              <>
                <tr>
                  <td class="align-middle">
                    <div class="d-flex align-items-center">
                      <span class="avatar avatar-md avatar-primary">
                        <span class="avatar-initials rounded-circle">
                          {school.fullname
                            .split(" ")[0]
                            .charAt(0)
                            .toUpperCase() +
                            school.fullname
                              .split(" ")[1]
                              .charAt(0)
                              .toUpperCase()}
                        </span>
                      </span>
                      <div class="ms-3">
                        <h5 class="mb-0">
                          <a class="text-inherit">{school.fullname} </a>
                        </h5>
                      </div>
                    </div>
                  </td>
                  <td class="align-middle">
                    <a href="javascript:void(0)">{school.pay_id}</a>
                  </td>
                  <td class="align-middle">{school.pay_type}</td>
                  <td class="align-middle">₦{school.amount}</td>
                  <td class="align-middle">{school.tm_date}</td>
                  <td class="align-middle ">
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-dark mb-2"
                      id={school.token}
                      onClick={(e) => {
                        fetchInfo(e.target.id);
                      }}
                      data-bs-toggle="modal"
                      data-bs-target=".gd-example-modal-lg"
                    >
                      <i class="bi bi-credit-card fs-4"></i> Pay Now
                    </button>
                  </td>
                </tr>
              </>
            )}
          </>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="5">
            <div
              className="col-md-12 text-center py-5 mt-5"
              onMouseMove={fetchOrder}
              onTouchMove={fetchOrder}
            >
              <div class="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>

              <h4 className="mt-6 h5 text-dark">Touch Screen With Mouse</h4>
            </div>
          </td>
        </tr>
      );
    }
  };

  return (
    <div class="container-fluid p-4 py-12">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12 mb-2">
          <div
            class="d-lg-flex
          align-items-center justify-content-between"
          >
            <div class="mb-2 mb-lg-0">
              <h1 class="mb-0 h2 fw-bold">Task Lists</h1>
            </div>
            <div class="d-flex align-items-center">
              <Link
                to="/promo"
                class="btn btn-icon fs-3 btn-light border border-2 rounded-circle btn-dashed ms-2"
              >
                +
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 mb-4">
          <ul class="nav nav-lb-tab">
            <li class="nav-item ms-0 me-3">
              <Link to="/home" class="nav-link">
                Dashboard
              </Link>
            </li>
            <li class="nav-item mx-3">
              <Link to="/task" class="nav-link active">
                Task
              </Link>
            </li>
            <li class="nav-item mx-3">
              <Link to="/users" class="nav-link">
                Users
              </Link>
            </li>
            <li class="nav-item mx-3">
              <Link to="/promos" class="nav-link">
                All Promo
              </Link>
            </li>
            <li class="nav-item mx-3">
              <Link to="/files" class="nav-link">
                Files
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-12" onMouseMove={fetchData} onTouchMove={fetchData}>
          <div class="card">
            <div class="card-header">
              <h4 class="mb-0 text-dark">School Task</h4>
            </div>

            <CustomScroll>
              <div class="table-responsive overflow-y-hidden">
                <table class="table mb-10 text-nowrap table-hover table-borderless">
                  <thead class="table-white">
                    <tr>
                      <th scope="col" class="border-top-0">
                        Agent Name
                      </th>
                      <th scope="col" class="border-top-0 ">
                        School Name
                      </th>
                      <th scope="col" class="border-top-0 ">
                        School Contact
                      </th>
                      <th scope="col" class="border-top-0 ">
                        School Population
                      </th>
                      <th scope="col" class="border-top-0 "></th>
                    </tr>
                  </thead>
                  <tbody>{renderTable()}</tbody>
                </table>
              </div>
            </CustomScroll>
          </div>
        </div>

        <div class="col-12" onMouseMove={fetchPay} onTouchMove={fetchPay}>
          <div class="card">
            <div class="card-header">
              <h4 class="mb-0 text-dark">Payout Task</h4>
            </div>

            <CustomScroll>
              <div class="table-responsive overflow-y-hidden">
                <table class="table mb-10 text-nowrap table-hover table-borderless">
                  <thead class="table-white">
                    <tr>
                      <th scope="col" class="border-top-0">
                        Agent Name
                      </th>
                      <th scope="col" class="border-top-0 ">
                        Pay ID
                      </th>
                      <th scope="col" class="border-top-0 ">
                        Pay Type
                      </th>
                      <th scope="col" class="border-top-0 ">
                        Amount
                      </th>
                      <th scope="col" class="border-top-0 ">
                        Time & Date
                      </th>
                      <th scope="col" class="border-top-0 "></th>
                    </tr>
                  </thead>
                  <tbody>{renderPayTable()}</tbody>
                </table>
              </div>
            </CustomScroll>
          </div>
        </div>

        <div class="col-12" onMouseMove={fetchOrder} onTouchMove={fetchOrder}>
          <div class="card">
            <div class="card-header">
              <h4 class="mb-0 text-dark">Order Tasks</h4>
            </div>

            <CustomScroll>
              <div class="table-responsive overflow-y-hidden">
                <table class="table table-md mb-10 text-nowrap table-hover table-borderless">
                  <thead class="table-white">
                    <tr>
                      <th scope="col" class="border-top-0">
                        Agent Name
                      </th>
                      <th scope="col" class="border-top-0">
                        Order No.
                      </th>
                      <th scope="col" class="border-top-0 ">
                        Address
                      </th>
                      <th scope="col" class="border-top-0 ">
                        Contact
                      </th>
                      <th scope="col" class="border-top-0 ">
                        Amount
                      </th>
                      <th scope="col" class="border-top-0 "></th>
                    </tr>
                  </thead>
                  <tbody>{renderOrderTable()}</tbody>
                </table>
              </div>
            </CustomScroll>
          </div>
        </div>
      </div>

      <div
        class="modal gd-example-modal-lg"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title h3 text-dark" id="exampleModalLabel">
                Payout system
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">{renderUserInfo()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskApp;
