import React, { useState } from "react";

import axios from "axios";

import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import Chart from "react-apexcharts";

toast.configure();

const TrackerApp = () => {
  const getusers = useSelector((state) => state.user.value);

  const getname = getusers.fullname;

  const getemail = getusers.email;

  let fname_1;

  if (getname) {
    fname_1 = getname.split(" ")[1];
  } else {
    fname_1 = "";
  }

  const [state, setState] = React.useState({
    isSubmit: "",
    buttonname: "Get Report",
  });

  const [tracker, setTracker] = useState(null);
  const [profile, setProfile] = useState(
      {
      num_school: 0,
      num_pay: 0,
      num_order: 0,
      num_reset: 0,
      login: 0,  
      }
  );
  const [disable, setDisable] = useState(false);

  const onSubmit = (b) => {
    b.preventDefault();

    const user_contact = {
      email: state.account,
    };

    axios({
      method: "post",
      url: "/v1/tracker.php",
      data: user_contact,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response.data.data.account_name);
        if (response.data) {
          toast.success(
            "Success: Report For " + state.account + " Has Been Generated!"
          );
          const view = response.data;
          setTracker(view);
          setDisable(true);
          setState({
            isSubmit: "spinner-grow spinner-grow-sm",
            buttonname: "Report Generated ",
            account: state.account,
          });
        } else {
          toast.error("Error: Request Failed! Try again");
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check your connection..");
      });

    axios({
      method: "post",
      url: "/v1/statistics.php",
      data: user_contact,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response.data.data.account_name);
        if (response.data) {
          const view = response.data;
          setProfile(view);
          setDisable(true);
        } else {
          toast.error("Error: Request Failed! Try again");
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check your connection..");
      });
  };

  const renderTable = () => {
    if (tracker == "") {
      return (
        <>
          <tr>
            <td colspan="5">
              <div className="col-md-12 py-5 mt-1 text-center">
                <img
                  src="../assets/images/background/empty.png"
                  alt="Gallery image 3"
                  class="gallery__img rounded-3"
                  style={{ width: "20%" }}
                />

                <h4 className="fs-3 fw-bold mt-4">No Results Found...</h4>
              </div>
            </td>
          </tr>
        </>
      );
    } else if (tracker) {
      return tracker.map((school, index) => {
        const TimeDate = new Date(school.tm_date).toDateString();
        //console.log(school.tm_date)
        return (
          <>
            <tr>
              <td class="align-middle">{index + 1}</td>
              <td class="align-middle">{school.name}</td>
              <td class="align-middle">{school.title}</td>
              <td class="align-middle">{school.device_type}</td>
              <td class="align-middle">{school.date_time}</td>
            </tr>
          </>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="4">
            <div className="col-md-12 text-center py-5 mt-5">
              <div class="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>

              <h4 className="mt-6 h5 text-dark">Touch Screen With Mouse</h4>
            </div>
          </td>
        </tr>
      );
    }
  };

  // console.log(profile.trn_date)

  const config = {
    series: [
      profile.num_school,
      profile.num_pay,
      profile.num_order,
      profile.num_reset,
    ],
    options: {
      labels: [
        "Number of schools",
        "Number of payouts",
        "Number of orders",
        "Number of password resets",
      ],
      chart: {
        type: "donut",
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      theme: {
        palette: "palette1",
      },
      dataLabels: {
        enabled: false,
      },
      cutout: "80%",
      cutoutPercentage: 90,
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        animateScale: true,
        animateRotate: true,
      },
      tooltips: {
        enabled: true,
        intersect: false,
        mode: "nearest",
        bodySpacing: 5,
        yPadding: 10,
        xPadding: 10,
        caretPadding: 0,
        displayColors: false,
        cornerRadius: 4,
        footerSpacing: 0,
        titleSpacing: 0,
      },
      plugins: {
        legend: {
          display: false,
          show: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            labels: [
              "Total Users",
              "Total Income Revenue",
              "Total Made Revenue",
              "Total Payments",
              "Total Proposals",
            ],
            chart: {
              width: 270,
              height: 200,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
    },
  };

  var d = new Date(profile.login);
  var tm_date = d.toDateString() + ' ' + d.toTimeString();

  console.log(window.navigator.userAgent)


  return (
    <div class="container-fluid p-4 py-12">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12 mb-2">
          <div
            class="d-lg-flex
          align-items-center justify-content-between"
          >
            <div class="mb-2 mb-lg-0">
              <h1 class="mb-0 h2 fw-bold">
                Tracker App{" "}
                <span class="badge bg-success ms-2 fs-4">Ver 1.0</span>
              </h1>
            </div>
            <div class="d-flex align-items-center">
              <Link
                to="/promo"
                class="btn btn-icon fs-3 btn-light border border-2 rounded-circle btn-dashed ms-2"
              >
                +
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12 py-3">
          <div class="card">
            <div class="card-header">
              <h4 class="mb-0 text-primary">Tracker System</h4>
            </div>

            <div class="card-body">
              <p>
                Get all activities of user through the Tracker System. Fix in
                the email address of the user/agent, click on the button to get
                details and activities of the user/agent.
              </p>

              <h4>Enter Email Address</h4>
              <form class="d-flex align-items-center mt-3" onSubmit={onSubmit}>
                <div className="row">
                  <div class="col-md-7 mb-5">
                    <input
                      type="email"
                      class="form-control form-control-md px-4"
                      placeholder="Search user/agent"
                      required
                      onChange={(b) => {
                        setState({
                          account: b.target.value,
                          buttonname: "Get Report",
                        });
                      }}
                    />
                  </div>
                  <div class="col-md-5 mb-5">
                    <button type="submit" class="btn btn-primary">
                      <span className="fs-5">{state.buttonname}</span>{" "}
                      <span className={state.isSubmit}></span>
                    </button>
                  </div>
                </div>
              </form>
            </div>

            {disable ? (
              <>
                <div class="p-4">
                  <h4 class="mb-0 fw-semi-bold text-dark">All Activities</h4>
                </div>

                <div className="row">
                  <div className="col-md-5">
                    <Chart
                      options={config.options}
                      series={config.series}
                      type="donut"
                      height={260}
                    />
                  </div>

                  <div className="col-md-1">
                    <div class="d-flex" style={{ height: "200px" }}>
                      <div class="vr"></div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item px-0">
                        <div
                          class="d-flex justify-content-between
                      align-items-center"
                        >
                          <div class="d-flex align-items-center">
                            <i class="bi bi-display fs-3 text-primary"></i>
                            <div class="ms-2">
                              <h5 class="mb-0 text-body">Device Type - {profile.device}</h5>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li class="list-group-item px-0">
                        <div
                          class="d-flex justify-content-between
                      align-items-center"
                        >
                          <div class="d-flex align-items-center">
                            <i class="bi bi-cash-coin fs-3 text-primary"></i>
                            <div class="ms-2">
                              <h5 class="mb-0 text-body">Total Earning  - {profile.total}</h5>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li class="list-group-item px-0">
                        <div
                          class="d-flex justify-content-between
                      align-items-center"
                        >
                          <div class="d-flex align-items-center">
                            <i class="bi bi-piggy-bank fs-3 text-primary"></i>
                            <div class="ms-2">
                              <h5 class="mb-0 text-body">Current Investment  - {profile.invest}</h5>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li class="list-group-item px-0">
                        <div
                          class="d-flex justify-content-between
                      align-items-center"
                        >
                          <div class="d-flex align-items-center">
                            <i class="bi bi-clock-history fs-3 text-primary"></i>
                            <div class="ms-2">
                              <h5 class="mb-0 text-body">Last Time Login  - {tm_date}</h5>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="p-4 py-6">
                  <h4 class="mb-0 fw-semi-bold text-dark">System Activities</h4>
                </div>

                <div class="table-responsive overflow-y-hidden">
                  <table class="table mb-10 text-nowrap table-hover table-borderless">
                    <thead class="table-white">
                      <tr>
                        <th scope="col" class="border-top-0">
                          S/N
                        </th>
                        <th scope="col" class="border-top-0">
                          Agent Name
                        </th>
                        <th scope="col" class="border-top-0 ">
                          Syetem
                        </th>
                        <th scope="col" class="border-top-0 ">
                          Device
                        </th>
                        <th scope="col" class="border-top-0 ">
                          Time & Date
                        </th>
                      </tr>
                    </thead>
                    <tbody>{renderTable()}</tbody>
                  </table>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackerApp;
